import { getActiveTenant, getOrganizations, setTenant } from '@/api/workspaces.ts'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert.tsx'
import { Button } from '@/components/ui/button.tsx'
import { toast } from '@/components/ui/use-toast.ts'
import { QueryKeys } from '@/constants/QueryKeys.ts'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useRouter } from '@tanstack/react-router'
import { t } from 'i18next'
import { Check, ChevronDown, ChevronUp, Loader2, RocketIcon } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '../ui/dropdown-menu'

export const WorkspaceSelector: React.FC = () => {
    const router = useRouter()
    const queryClient = useQueryClient()
    const [dropDownOpen, setDropDownOpen] = useState(false)
    const [tenantSwitchStatus, setTenantSwitchStatus] = useState({
        isSwitching: false,
        switched: false,
    })
    const [tenantSelectorVisible, setTenantSelectorVisible] = React.useState(false)

    const getOrganizationsQuery = useQuery({
        queryKey: [QueryKeys.ORGANIZATIONS],
        queryFn: () => getOrganizations(),
    })

    const getActiveTenantQuery = useQuery({
        queryKey: [QueryKeys.ACTIVE_TENANT],
        queryFn: () => getActiveTenant(),
    })

    useEffect(() => {
        if (getOrganizationsQuery.data) {
            const multipleTenants =
                getOrganizationsQuery.data.length > 1 ||
                getOrganizationsQuery.data.some((organization) => organization.tenants.length > 1)

            setTenantSelectorVisible(multipleTenants)
        }
    }, [getOrganizationsQuery.data])

    const setUserTenant = async (organizationId: string, tenantId: string) => {
        // set the tenant for the user
        console.log('Setting the tenant for the user')
        try {
            const tenantSwitchResult = await setTenant(organizationId, tenantId)
            if (tenantSwitchResult) {
                setTenantSwitchStatus({ isSwitching: true, switched: false })
                // Invalidates all queries
                await queryClient.invalidateQueries()
                await router.invalidate()
                setTenantSwitchStatus({ isSwitching: false, switched: true })
                window.location.replace(router.basepath.toString())
            } else {
                //Closes the modal. The user is most likely attempting to switch to the same tenant.
                toast({
                    title: t('error_title'),
                    description: t('workspaces.error_description'),
                    variant: 'destructive',
                })
                setDropDownOpen(false)
            }
        } catch (error: any) {
            toast({
                title: t('error_title'),
                description: error.message ?? t('workspaces.error_description'),
                variant: 'destructive',
            })
            setDropDownOpen(false)
        }
    }

    return (
        tenantSelectorVisible && (
            <DropdownMenu open={dropDownOpen} onOpenChange={setDropDownOpen}>
                <DropdownMenuTrigger asChild>
                    <Button
                        variant={'ghost'}
                        className="gap-2 shadow-sm pl-4 pr-4 rounded-full bg-primary-muted border border-primary"
                    >
                        <p className="font-medium text-base text-primary">
                            {getActiveTenantQuery.data?.name}
                        </p>
                        {!dropDownOpen ? (
                            <ChevronDown className="w-4 text-primary dark:text-primary hover:text-primary dark:hover:text-primary" />
                        ) : (
                            <ChevronUp className="w-4 text-primary dark:text-primary hover:text-primary dark:hover:text-primary" />
                        )}
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                    align={'end'}
                    className={'w-[254px] border border-gray-250 bg-gray-50 p-0'}
                >
                    <div className="flex justify-left gap-4 pt-4 pl-4">
                        <h1 className="uppercase font-bold text-sm text-gray-350">
                            {t('workspaces.select_tenant')}
                        </h1>
                    </div>
                    <hr className="text-gray-250 mt-2 mb-2" />
                    <div className="pl-2 pr-2 max-h-60 overflow-auto">
                        {tenantSwitchStatus.isSwitching ? (
                            <div className="flex flex-col w-full">
                                <Alert>
                                    <RocketIcon className="h-4 w-4 mt-2" />
                                    <AlertTitle>
                                        {t('workspaces.switching_workspace_header')}
                                    </AlertTitle>
                                    <AlertDescription>
                                        {t('workspaces.switching_workspace_message')}
                                    </AlertDescription>
                                </Alert>
                                <Loader2 className="h-8 w-8 my-8 animate-spin self-center" />
                            </div>
                        ) : tenantSwitchStatus.switched ? (
                            <div className="flex flex-col w-full">
                                <Alert>
                                    <Check className="h-4 w-4 mt-2" />
                                    <AlertTitle>
                                        {t('workspaces.workspace_switched_header')}
                                    </AlertTitle>
                                    <AlertDescription>
                                        {t('workspaces.workspace_switched_message')}
                                    </AlertDescription>
                                </Alert>
                                <Loader2 className="h-8 w-8 my-8 animate-spin self-center" />
                            </div>
                        ) : (
                            <div className="flex flex-col justify-left">
                                {getOrganizationsQuery.data
                                    ? getOrganizationsQuery.data
                                          .flatMap((org) =>
                                              org.tenants.map((tenant) => ({
                                                  id: org.id,
                                                  externalId: org.externalId,
                                                  tenantId: tenant.id,
                                                  tenantIdentifier: tenant.identifier,
                                              }))
                                          )
                                          .sort((a, b) =>
                                              a.tenantIdentifier.localeCompare(b.tenantIdentifier)
                                          )
                                          .map((organization, _) => (
                                              <div
                                                  key={organization.tenantId}
                                                  className="flex flex-col bg-background"
                                              >
                                                  <div
                                                      key={organization.tenantId}
                                                      className="flex grow shrink"
                                                  >
                                                      <Button
                                                          className={`${getActiveTenantQuery.data?.id === organization.tenantId ? 'bg-primary-muted font-bold' : ''} grow shrink w-full break-all truncate justify-start text-left border-none rounded-none text-gray-550 text-sm`}
                                                          variant={'ghost'}
                                                          key={organization.tenantId}
                                                          onClick={() => {
                                                              if (
                                                                  getActiveTenantQuery.data?.id !==
                                                                  organization.tenantId
                                                              ) {
                                                                  setUserTenant(
                                                                      organization.externalId,
                                                                      organization.tenantId
                                                                  )
                                                              }
                                                          }}
                                                      >
                                                          {organization.tenantIdentifier}
                                                      </Button>
                                                  </div>
                                              </div>
                                          ))
                                    : (t('loading') as string)}
                            </div>
                        )}
                    </div>
                </DropdownMenuContent>
            </DropdownMenu>
        )
    )
}
