import type { Project } from '@/api/projects'
import { CommandItem } from '../ui/command'
import { cn } from '@/lib/utils'
import { Checkbox } from '../ui/checkbox'
import type { CompoundFilterParameters } from './FeedHeader'
import Airia from '@/assets/models/Airia.svg?react'

export default ({
    item,
    thisParam,
    handleSetValue,
}: {
    item: Project
    index: number
    thisParam: CompoundFilterParameters
    handleSetValue: (val: string) => void
}) => {
    return (
        <CommandItem
            key={item.id}
            value={item.id}
            keywords={[item.name]}
            onSelect={(currentValue) => {
                handleSetValue(currentValue)
            }}
            className={cn(
                'aria-selected:bg-accent aria-selected:text-accent-foreground flex items-center',
                thisParam.value.includes(item.id) && 'bg-accent text-accent-foreground'
            )}
        >
            <Checkbox
                checked={thisParam.value.includes(item.id)}
                className="w-4 h-4 mr-2 text-accent-foreground"
            />
            {item.projectIcon ? (
                <img src={item.projectIcon} className={'h-4 w-4 mr-1'} />
            ) : (
                <Airia className={'h-4 w-4 mr-1'} />
            )}
            {item.name}
        </CommandItem>
    )
}
