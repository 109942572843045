import { LibraryModel } from '@/api/library.ts'
import { Plus } from 'lucide-react'

import ProviderIcon from '@/components/modelLibrary/ProviderIcon.tsx'

interface ModelLibraryCardProps {
    model: LibraryModel
    onClick?: () => void
    onProjectDropdownClick?: (mode: 'new' | 'existing') => void
}

export default function ModelLibraryCard({ model, onClick }: ModelLibraryCardProps) {
    return (
        <div
            onClick={() => onClick && onClick()}
            className={`border rounded-sm border-border bg-background shadow-none p-4 ${onClick && 'cursor-pointer hover:bg-card'}`}
        >
            <div className={'flex flex-col justify-between h-full gap-4'}>
                <div>
                    <div className={'flex justify-between items-center'}>
                        <span className={'text-xl'}>{model.name}</span>
                        <ProviderIcon
                            provider={model.provider}
                            className={'h-10 w-10 dark:bg-white rounded-md p-1'}
                        />
                    </div>
                    <span className="line-clamp-4 text-foreground text-xs">
                        {model.description}
                    </span>
                </div>
                <div className="flex items-end justify-between mt-auto text-nowrap">
                    <div className="flex gap-2">
                        {model.tags.slice(0, 3).map((tag) => (
                            <span key={tag} className="bg-card rounded-full px-2 py-1 text-sm">
                                {tag}
                            </span>
                        ))}
                        {model.tags.length > 4 && (
                            <span
                                className="bg-card rounded-full px-2 py-1 text-sm"
                                title={model.tags.toString()}
                            >
                                {`+${model.tags.length - 4}`}
                            </span>
                        )}
                    </div>
                    <div>
                        <Plus />
                    </div>
                </div>
            </div>
        </div>
    )
}
