import type React from 'react'
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader } from './ui/dialog'
import { Button } from './ui/button'
import { Loader2 } from 'lucide-react'
import { X } from '@untitled-ui/icons-react'
import { t } from 'i18next'

type LoadingModalProps = {
    loading: boolean
    hideDescription?: boolean
    hideCancelButton?: boolean
    onCancel?: () => void
}

const LoadingModal: React.FC<LoadingModalProps> = ({
    loading,
    hideDescription,
    hideCancelButton,
    onCancel,
}) => {
    return (
        <Dialog open={loading}>
            <DialogContent
                className="flex flex-col content-center items-center justify-center max-w-[454px] p-6"
                hideCloseButton
            >
                <DialogHeader className="flex flex-col gap-2 justify-center items-center content-center">
                    <Loader2 className="w-10 h-10 flex-shrink-0 animate-spin text-primary" />
                    <p className="text-gray-550 font-medium text-2xl">
                        {t('loading_modal.loading')}
                    </p>
                </DialogHeader>
                {!hideDescription && (
                    <DialogDescription>
                        <p className="text-md text-gray-550">{t('loading_modal.please_wait')}</p>
                    </DialogDescription>
                )}
                {!hideCancelButton && (
                    <DialogFooter>
                        <Button
                            variant={'ghost'}
                            onClick={() => onCancel?.()}
                            className="gap-2 flex flex-row items-center px-md font-base"
                        >
                            <span>{t('cancel')}</span>
                            <X className="w-5 h-5" />
                        </Button>
                    </DialogFooter>
                )}
            </DialogContent>
        </Dialog>
    )
}

export default LoadingModal
