import apiRequest from '@/lib/apiClient'
import type { paginationAndSortingParams } from '@/components/pagination/paginationHeader.tsx'
export interface GetTenantResponseV1 {
    id: string
    status?: number
}

export interface PostTenantRequestV1 {
    emailAddress: string
}

export interface GetTenantDetailsResponseV1 {
    id: string
    balance: number
    enableAutoTopUp: boolean
    minimumBalanceForAutoTopUp?: number
    autoTopUpByValue?: boolean
    defaultPaymentMethodConfigured: boolean
    pools: PoolResponseV1[]
}

export interface PoolResponseV1 {
    id: string
    name: string
    balance: number
    maxBalance: number
    projects: string[]
}

export interface GetTenantBalanceResponseV1 {
    balance: number
}

export interface CreateBillingPortalRequestV1 {
    // note: this should be the current url that the browser is at, to redirect back to.
    returnUrl: string
}

export interface CreateBillingPortalResponseV1 {
    // this is a redirect url to stripe.
    url: string
}

export interface CreatePaymentRequestV1 {
    // note: this must be between 20 and 100
    quantity: number
    // note: this should be the current url that the browser is at, to redirect back to.
    callbackUrl: string
}

export interface CreatePaymentResponseV1 {
    // this is a redirect url to stripe.
    url: string
}

export interface FinancialTransaction {
    tenantId: string
    transactionDateTime: string
    transactionUser: string
    creditCardNumber: string
    creditCardVendor: string
    transactionAmount: number
    financialTransactionType: string
    chargeId: string
}
export interface GetTransactionsResponseV1 {
    items: FinancialTransaction[]
    totalCount: number
}

export const getTenantV1 = async (): Promise<GetTenantResponseV1 | null> => {
    return apiRequest(`/api/marketplace/v1/tenant`)
}

export const getTenantDetailsV1 = async (): Promise<GetTenantDetailsResponseV1 | null> => {
    return apiRequest(`/api/marketplace/v1/tenant/details`)
}

export const getTenantBalanceV1 = async (): Promise<GetTenantBalanceResponseV1 | null> => {
    return apiRequest(`/api/marketplace/v1/tenant/balance`)
}

export const createTenantV1 = async (request: PostTenantRequestV1): Promise<boolean> => {
    return apiRequest(`/api/marketplace/v1/tenant`, {
        method: 'POST',
        body: request,
    })
}

export const createBillingPortalV1 = async (
    request: CreateBillingPortalRequestV1
): Promise<CreateBillingPortalResponseV1> => {
    return apiRequest(`/api/marketplace/v1/billingportal`, {
        method: 'POST',
        body: request,
    })
}

export const createPaymentPortalV1 = async (
    request: CreatePaymentRequestV1
): Promise<CreatePaymentResponseV1> => {
    return apiRequest(`/api/marketplace/v1/payment`, {
        method: 'POST',
        body: request,
    })
}

export const getTransactions = async (
    queryParams?: paginationAndSortingParams | undefined
): Promise<GetTransactionsResponseV1> => {
    return apiRequest(`/api/marketplace/v1/FinancialTransactions`, {
        method: 'GET',
        queryParams: queryParams,
    })
}

export const downloadTransactionsCsv = (queryParams?: {
    pageNumber: number
    pageSize: number
    startTime?: number
    endTime?: number
}): Promise<string> => {
    return apiRequest('/api/marketplace/v1/FinancialTransactions/export/csv', {
        headers: {
            'Content-Type': 'text/csv',
        },
        queryParams: queryParams,
    })
}

export const getReceiptLink = async (chargeId: string): Promise<{ pdfLink: string }> => {
    return apiRequest(`/api/marketplace/v1/FinancialTransactions/receipt/${chargeId}`)
}
