import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { type LucideIcon } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from '@/lib/i18n'
import { cn } from '@/lib/utils'
import NewProjectForm from '../newProject/newProjectForm'

export const CreateNewProject = ({
    variant,
    onSuccess,
    size,
    Icon,
    customSizeClassName,
}: {
    variant:
        | 'default'
        | 'destructive'
        | 'outline'
        | 'secondary'
        | 'ghost'
        | 'link'
        | null
        | undefined
    size?: 'default' | 'sm' | 'lg' | 'icon' | 'custom'
    customSizeClassName?: string
    Icon?: LucideIcon
    onSuccess?: () => void
}) => {
    const [openCreate, setOpenCreate] = useState(false)
    const { t } = useTranslation()

    const onProjectFormSubmit = () => {
        setOpenCreate(false)
        onSuccess && onSuccess()
    }

    return (
        <div className={cn(customSizeClassName && customSizeClassName, '')}>
            <Button
                type="button"
                size={size === 'custom' ? undefined : size}
                className={cn(customSizeClassName && customSizeClassName, '')}
                onClick={(e) => {
                    e.stopPropagation()
                    setOpenCreate(true)
                }}
                variant={variant}
            >
                {Icon ? <Icon className="h-4 w-4" /> : t('project_create')}
            </Button>
            <Dialog open={openCreate} onOpenChange={setOpenCreate}>
                <DialogContent className="max-w-modal-md max-h-modal">
                    <DialogHeader>
                        <DialogTitle className={'text-2xl font-medium'}>Create project</DialogTitle>
                    </DialogHeader>
                    <NewProjectForm onSuccess={onProjectFormSubmit} />
                </DialogContent>
            </Dialog>
        </div>
    )
}
