import { type Group, getAllGroups } from '@/api/groups'
import {
    type User,
    createUser,
    assignUserToGroup,
    getAllInternalUsers,
    syncUsers,
} from '@/api/users'
import PaginationHeader, {
    type paginationAndSortingParams,
} from '@/components/pagination/paginationHeader'
import { Button } from '@/components/ui/button'
import { CardHeader } from '@/components/ui/card'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { toast } from '@/components/ui/use-toast'
import { QueryKeys } from '@/constants/QueryKeys'
import { usePagination } from '@/hooks/use-pagination'
import useSearchEffect from '@/hooks/use-search-effect'
import { useSorting } from '@/hooks/use-sorting'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { type SearchSchemaInput, createFileRoute } from '@tanstack/react-router'
import { useNavigate } from '@tanstack/react-router'
import { type ColumnDef, flexRender } from '@tanstack/react-table'
import { useDebounce } from '@uidotdev/usehooks'
import { RotateCcw, Loader2 } from 'lucide-react'
import type React from 'react'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from '@/lib/i18n'
import { DefaultDataTable } from '../../../components/DefaultDataTable'
import { FormItemError } from '@/components/ui/form'
import { RoleSelect } from '@/components/settings/RolesSelect'
import { formatDate } from '@/lib/utils'

interface UsersProps {
    // Define the props for the component here
    id: string
    name: string
    isActive: boolean
    email: string
    userName: string
    password: string
    confirmPassword: string
    enterprise: string
    userId: string
    groups: Group[]
}

export const Route = createFileRoute('/_mainLayout/settings/users')({
    component: () => {
        return <Users key={'users-overview'} />
    },
    validateSearch: (
        search: Record<string, unknown> & SearchSchemaInput
    ): paginationAndSortingParams => {
        return {
            pageNumber: search?.pageNumber as number,
            pageSize: search?.pageSize as number,
            sortBy: search?.sortBy as string,
        }
    },
})
const Users: React.FC = () => {
    const params = Route.useParams()
    const queryParams = Route.useSearch()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const { limit, onPaginationChange, paginationState } = usePagination({
        initialPageIndex: queryParams.pageNumber ? queryParams.pageNumber - 1 : 0,
        initialPageSize: queryParams.pageSize,
    })
    const { sortingState, onSortingChange, field, order } = useSorting({
        initialField: queryParams.sortBy ?? 'name',
        initialOrder: queryParams.sortDirection ?? 'ASC',
    })
    const queryClient = useQueryClient()
    const [searchValue, setSearchValue] = useState<string>('')
    const debouncedSearchValue = useDebounce(searchValue, 500)
    useSearchEffect({
        debouncedSearchValue,
        navigate,
        queryParams,
        params,
        onPaginationChange,
    })
    const AllUsersQuery = useQuery({
        queryKey: [QueryKeys.USERS, queryParams],
        queryFn: async () =>
            await getAllInternalUsers(
                queryParams.pageSize && queryParams.pageNumber
                    ? { ...queryParams }
                    : { pageSize: 25, pageNumber: 1 }
            ),
    })
    const AllGroupsQuery = useQuery({
        queryKey: [QueryKeys.GROUPS],
        queryFn: () => getAllGroups(),
        select: (data) => data.items,
    })

    // const handleDelete = async (id: string) => {
    //     if (window.confirm(t('confirmation_to_continue'))) {
    //         await deleteUserByAdmin(id).catch((error) => {
    //             toast({
    //                 title: 'Error',
    //                 description: error.message,
    //                 variant: 'destructive',
    //             })
    //         })
    //         await queryClient.invalidateQueries({ queryKey: [QueryKeys.ALL_USERS] })
    //         AllUsersQuery.refetch()
    //     }
    // }

    const handleUpdateUserGroups = async (user: User, groupsIds: string[]) => {
        try {
            const updatedUserGroups = user.groups.filter(
                (group) => group.name.toLowerCase().indexOf('tenant') > -1
            )
            for (const groupId of groupsIds) {
                let group = updatedUserGroups.find((g) => g.id === groupId)
                if (group) {
                    continue
                }
                group = user.groups.find((g) => g.id === groupId)
                if (group) {
                    updatedUserGroups.push(group)
                    continue
                }
                group = AllGroupsQuery.data?.find((g) => g.id === groupId)
                if (!group) {
                    throw new Error(`Group with id ${groupId} not found`)
                }
                updatedUserGroups.push(group)
            }
            user = { ...user, groups: updatedUserGroups }
            await assignUserToGroup(user)
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.ALL_USERS] })
            await AllUsersQuery.refetch()
            // const updatedUser = await getInternalUserById(user.id)
            // console.log('updated user response', updatedUser)
            // queryClient.setQueryData([QueryKeys.ALL_USERS], (oldData: UserList) => {
            //     console.log('old data', oldData)
            //     const { items, totalCount } = oldData || { items: [], totalCount: 0 }
            //     return {
            //         items: items.map((user) => (user.id === updatedUser.id ? {...updatedUser} : {...user})),
            //         totalCount,
            //     }
            // })
            toast({
                title: t('success_title'),
                description: t('user_roles_updated_successfully'),
                variant: 'default',
            })
        } catch (error) {
            toast({
                title: 'Error',
                description: (error as Error).message,
                variant: 'destructive',
            })
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.ALL_USERS] })
            AllUsersQuery.refetch()
        }
    }

    const columns: ColumnDef<User>[] = [
        {
            accessorKey: 'name',
            header: t('name'),
            cell: ({ row }) =>
                flexRender(({ firstName, lastName }) => {
                    return `${firstName} ${lastName}`
                }, row.original),
        },
        {
            accessorKey: 'email',
            header: 'Email',
        },
        {
            accessorKey: 'groups',
            header: t('roles'),
            cell: ({ row }) =>
                flexRender(
                    (user) => (
                        <div className="pr-5">
                            <RoleSelect
                                selectedRolesIds={user.groups.map((group) => group.id)}
                                setSelectedRolesIds={(groupsIds) =>
                                    handleUpdateUserGroups(user, groupsIds)
                                }
                            />
                        </div>
                    ),
                    row.original
                ),
        },
        {
            accessorKey: 'createdAt',
            header: t('created_date'),
            cell: ({ row }) => <p>{formatDate(row.original.updatedAt)}</p>,
        },
        // {
        //     accessorKey: 'status',
        //     header: t('columns.status'),
        //     cell: ({ row }) => {
        //         if (!row.original.isActive) {
        //             return (
        //                 <Badge className="rounded-full bg-warning-muted hover:bg-warning-muted">
        //                     <div className="flex flex-row gap-1 items-center text-success-foreground text-xs">
        //                         Inactive
        //                         <CircleSlash className={'h-4 w-4 text-yellow-600'} />
        //                     </div>
        //                 </Badge>
        //             )
        //         }
        //         return (
        //             <Badge className="rounded-full bg-success hover:bg-success">
        //                 <div className="flex flex-row gap-1 items-center text-success-foreground text-xs">
        //                     Active
        //                     <CheckCircle className={'h-4 w-4 text-green-600'} />
        //                 </div>
        //             </Badge>
        //         )
        //     },
        // },
        // {
        //     id: 'actions',
        //     enableHiding: false,
        //     cell: ({ row }) => {
        //         return (
        //             <DropdownMenu>
        //                 <DropdownMenuTrigger asChild>
        //                     <Button variant="ghost" className="h-8 w-8 p-0">
        //                         <span className="sr-only fixed">Open menu</span>
        //                         <MoreHorizontal className="h-4 w-4" />
        //                     </Button>
        //                 </DropdownMenuTrigger>
        //                 <DropdownMenuContent align="end">
        //                     <DropdownMenuLabel className="flex gap-1 "> Actions</DropdownMenuLabel>
        //                     <DropdownMenuItem>
        //                         <Link
        //                             //@ts-ignore
        //                             to="/settings/edit-user/$Id"
        //                             params={{
        //                                 //@ts-ignore
        //                                 Id: row.original.id,
        //                             }}
        //                             className="flex gap-1"
        //                         >
        //                             <Pencil className="h-4 w-4" />
        //                             {t('users_models.editUser')}
        //                         </Link>
        //                     </DropdownMenuItem>
        //                     <DropdownMenuItem
        //                         className="flex gap-1 text-red-500"
        //                         onClick={() => handleDelete(row.original.id)}
        //                     >
        //                         <Trash className="h-4 w-4" />
        //                         {t('users_models.deleteUser')}
        //                     </DropdownMenuItem>
        //                 </DropdownMenuContent>
        //             </DropdownMenu>
        //         )
        //     },
        // },
    ]

    useEffect(() => {
        navigate({
            search: {
                ...queryParams,
                pageNumber: paginationState.pageIndex + 1,
                pageSize: limit,
                sortBy:
                    //@ts-ignore
                    columns.find((col) => col.accessorKey === field) != null ? field : 'name',
                sortDirection: order,
            },
        })
    }, [paginationState, sortingState])

    const syncUsersMutation = useMutation({
        mutationFn: syncUsers,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [QueryKeys.USERS] })
            toast({
                title: t('success_title'),
                description: 'Users refreshed.',
            })
        },
        onError: () => {
            toast({
                title: t('error_title'),
                description: t('error_description_generic'),
                variant: 'destructive',
            })
        },
    })

    return (
        <div className="">
            <CardHeader>
                <div className="flex flex-row justify-between">
                    <div className="flex flex-col gap-2 grow">
                        <PaginationHeader filter={searchValue} setFilter={setSearchValue} />
                    </div>
                    <div className="flex items-center gap-3">
                        <Button
                            type="button"
                            variant="ghost"
                            disabled={syncUsersMutation.isPending}
                            onClick={async () => {
                                await syncUsersMutation.mutate()
                                await queryClient.invalidateQueries({
                                    queryKey: [QueryKeys.USERS],
                                })
                            }}
                        >
                            {t('users_sync')}
                            <RotateCcw className="w-5 h-5 ml-2" />
                        </Button>
                        <AddNewUser
                            onSuccess={() =>
                                toast({
                                    title: t('success_title'),
                                    description: t('users_models.invite_sent_successfully'),
                                })
                            }
                        />
                    </div>
                </div>
            </CardHeader>
            <DefaultDataTable
                className="h-[70vh]"
                data={AllUsersQuery.data?.items ?? []}
                columns={columns}
                rowsPerPage={queryParams.pageSize}
                serverPagination
                totalCount={AllUsersQuery.data?.totalCount}
                limit={limit}
                onPaginationChange={onPaginationChange}
                onSortingChange={onSortingChange}
                paginationState={paginationState}
                sortingState={sortingState}
            />
        </div>
    )
}

interface AddNewUserProps {
    onSuccess?: () => void
}

export const AddNewUser: React.FC<AddNewUserProps> = ({ onSuccess }) => {
    const queryClient = useQueryClient()
    const [openModal, setOpenModal] = useState(false)
    const appForm = useForm<UsersProps>()
    const { t } = useTranslation()

    const handleCreateUser = async (data: UsersProps) => {
        try {
            const nameWords = data.name.trim().split(/\s+/)
            const firstName = nameWords[0]
            const lastName = nameWords.slice(1).join(' ')

            //@ts-ignore
            await createUser({
                ...data,
                firstName,
                lastName,
            }).catch((error) => {
                console.log(error)
                toast({
                    title: 'Error',
                    description: error.message,
                    variant: 'destructive',
                })
            })

            await queryClient.invalidateQueries({ queryKey: [QueryKeys.USERS] })
            setOpenModal(false)
            onSuccess && onSuccess()
        } catch (error: any) {
            console.error(error)
            toast({
                title: t('error_title'),
                description: error.message,
                variant: 'destructive',
            })
        }
    }

    const AllGroupsQuery = useQuery({
        queryKey: [QueryKeys.GROUPS],
        queryFn: () => getAllGroups(),
        select: (data) => data.items,
    })

    useEffect(() => {
        appForm.register('groups', {
            validate: (value) => {
                return (value ?? []).length > 0
            },
        })
    }, [])

    useEffect(() => {
        appForm.reset({
            name: '',
            email: '',
            groups: [],
        })
    }, [openModal])

    const getGroupsByIds = (groupsIds: string[]) => {
        const groups = []
        for (const id of groupsIds) {
            const group = AllGroupsQuery.data?.find((group) => group.id === id)
            if (!group) {
                throw new Error(`Group with id ${id} not found`)
            }
            groups.push(group)
        }
        return groups
    }

    return (
        <div>
            <Button type="button" onClick={() => setOpenModal(true)} variant={'default'}>
                {t('user_add')}
            </Button>
            <Dialog open={openModal} onOpenChange={setOpenModal} aria-label="Create User">
                <DialogContent className="max-w-modal-md max-h-modal rounded-2xl last:gap-0">
                    <DialogHeader>
                        <DialogTitle>{t('users_models.addUser')}</DialogTitle>
                        <DialogDescription hidden>{t('users_models.addUser')}</DialogDescription>
                    </DialogHeader>
                    <form
                        onSubmit={appForm.handleSubmit(handleCreateUser)}
                        className="flex flex-col gap-4"
                    >
                        <div className={'flex flex-col gap-4'}>
                            <div>
                                <Label htmlFor="name" className="text-left">
                                    {t('users_models.name')}
                                </Label>
                                <Input
                                    id="name"
                                    type="text"
                                    className="col-span-3"
                                    {...appForm.register('name', {
                                        required: true,
                                        validate: (value) => {
                                            const words = value.trim().split(/\s+/)
                                            return words.length >= 2
                                        },
                                    })}
                                    onBlur={() => appForm.trigger('name')}
                                />
                                {appForm.formState.errors.name && (
                                    <FormItemError>
                                        {t('users_models.full_name_required')}
                                    </FormItemError>
                                )}
                            </div>
                            <div>
                                <Label htmlFor="email" className="text-left">
                                    {t('users_models.email')}
                                </Label>
                                <Input
                                    id="email"
                                    type="text"
                                    className="col-span-3"
                                    {...appForm.register('email', {
                                        required: true,
                                        pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                    })}
                                    onBlur={() => appForm.trigger('email')}
                                />
                                {appForm.formState.errors.email && (
                                    <FormItemError>{t('users_models.invalid_email')}</FormItemError>
                                )}
                            </div>
                            <div>
                                <Label className="text-left">{t('users_models.roles')}</Label>
                                <Controller
                                    name="groups"
                                    control={appForm.control}
                                    rules={{
                                        required: true,
                                        minLength: 1,
                                        onBlur: () => appForm.trigger('groups'),
                                    }}
                                    render={({ field }) => (
                                        <RoleSelect
                                            selectedRolesIds={(field.value ?? []).map(
                                                (group) => group.id
                                            )}
                                            setSelectedRolesIds={(groupsIds) =>
                                                field.onChange(getGroupsByIds(groupsIds))
                                            }
                                            onToggle={(open) => !open && field.onBlur()}
                                        />
                                    )}
                                />
                                {appForm.formState.errors.groups && (
                                    <FormItemError>
                                        {t('users_models.at_least_one_role_required')}
                                    </FormItemError>
                                )}
                            </div>
                        </div>
                        <DialogFooter>
                            {appForm.formState.isSubmitting ? (
                                <div className="flex flex-col w-full">
                                    <Loader2 className="w-8 h-8 animate-spin self-center" />
                                </div>
                            ) : (
                                <Button
                                    type="submit"
                                    onClick={appForm.handleSubmit(handleCreateUser)}
                                    disabled={!appForm.formState.isValid}
                                >
                                    {t('users_models.send_invite')}
                                </Button>
                            )}
                        </DialogFooter>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    )
}
