import { formatCurrency } from '@/lib/utils.ts'
import { Button } from '@/components/ui/button.tsx'
import { ArrowRight } from 'lucide-react'
import React, { useEffect } from 'react'
import { createBillingPortalV1, createPaymentPortalV1 } from '@/api/marketplace.ts'
import useTenantQuery from '@/hooks/use-tenant-query.ts'
import { LOW_BALANCE_THRESHOLD } from '@/components/billing/accountSummary.tsx'
import LoadingModal from '../LoadingModal'

interface AddFundsProps {
    onDoneClick: () => void
}

const AddFunds: React.FC<AddFundsProps> = ({ onDoneClick }) => {
    const [balance, setBalance] = React.useState(0)
    const [lowBalance, setLowBalance] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const { details, isLoading } = useTenantQuery()

    useEffect(() => {
        if (!isLoading && details) {
            setBalance(details.balance)
            setLowBalance(details.balance <= LOW_BALANCE_THRESHOLD)
        }
    }, [details, isLoading])

    const onAddFundsClick = async () => {
        try {
            setLoading(true)
            const res = await createPaymentPortalV1({
                callbackUrl: window.location.href,
                quantity: 20000,
            })
            window.location.href = res.url
        } catch (err) {
            console.log('error adding credit', err)
        } finally {
            setLoading(false)
        }
    }

    const onAddCreditCardClick = async () => {
        try {
            setLoading(true)
            const res = await createBillingPortalV1({
                returnUrl: window.location.href,
            })
            window.location.href = res.url
        } catch (err) {
            console.log('error adding credit card', err)
        } finally {
            setLoading(false)
        }
    }
    return (
        <div>
            <div className={'flex flex-col gap-4'}>
                <h3>Account Balance</h3>
                <div
                    className={`w-full flex flex-col justify-center items-center gap-4 py-8 ${lowBalance ? 'bg-error-muted' : 'bg-primary-muted'} rounded-lg`}
                >
                    <span
                        className={`${lowBalance ? 'text-destructive' : 'text-primary'} text-5xl`}
                    >
                        {formatCurrency(balance)}
                    </span>
                    <Button
                        variant={'outline'}
                        onClick={() => onAddFundsClick()}
                        className={`flex gap-2 ${lowBalance ? 'text-destructive hover:text-destructive border-destructive' : 'text-primary border-gray-350'} bg-transparent px-8 py-6 rounded-full`}
                    >
                        <span className={'font-bold'}>Add Funds</span>
                        <ArrowRight className={'h-4 w-4'} />
                    </Button>
                </div>
                <div className={'flex flex-col mb-10 gap-4'}>
                    <div className={'flex justify-between items-center'}>
                        <h3>Add funds automatically</h3>
                        <span className={'text-gray-950'}>
                            {'Autopay is '}
                            <span
                                className={
                                    details?.defaultPaymentMethodConfigured
                                        ? 'text-success'
                                        : 'text-gray-950'
                                }
                            >
                                {details?.defaultPaymentMethodConfigured ? 'on' : 'off'}
                            </span>
                        </span>
                    </div>
                    <span className={'text-gray-450'}>
                        If you exceed your available funds, we will recoup the cost and add $10 to
                        your account billed to the credit card on file.
                    </span>
                    <div className={'flex'}>
                        <span
                            onClick={() => onAddCreditCardClick()}
                            className={
                                'flex items-center text-sm text-primary font-bold hover:underline cursor-pointer'
                            }
                        >
                            {`${details?.defaultPaymentMethodConfigured ? 'Manage Credit Card' : 'Add Credit Card'}`}{' '}
                            <ArrowRight className={'h-4 w-4 ml-2'} />
                        </span>
                    </div>
                </div>
            </div>
            <div className={'flex justify-end'}>
                <Button onClick={onDoneClick} className={'px-10 py-6'}>
                    Done
                </Button>
            </div>
            <LoadingModal onCancel={() => setLoading(false)} loading={loading} />
        </div>
    )
}

export default AddFunds
