import { inviteUser } from '@/api/users'
import { Button } from '@/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
} from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { toast } from '@/components/ui/use-toast'
import { QueryKeys } from '@/constants/QueryKeys'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { t } from 'i18next'
import { useForm } from 'react-hook-form'

export const Route = createFileRoute('/_legalLayout/$projectId/legal/users/new')({
    component: () => {
        const navigate = useNavigate()

        return (
            <Dialog open onOpenChange={() => navigate({ to: '../' })}>
                <DialogContent className="max-w-modal-md max-h-modal rounded-2xl">
                    <DialogHeader className="text-2xl font-medium font-normal leading-10 h-[40px] p-0 m-0">
                        Invite users
                    </DialogHeader>
                    <DialogDescription className="p-0 h-[40px] m-0">
                        We'll send them a link to get started
                    </DialogDescription>
                    <AddUser />
                </DialogContent>
            </Dialog>
        )
    },
})

function AddUser() {
    const { register, handleSubmit, formState } = useForm()
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const inviteUserMutation = useMutation({
        mutationFn: ({
            firstName,
            lastName,
            email,
        }: {
            firstName: string
            lastName: string
            email: string
        }) => inviteUser({ firstName, lastName, email }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [QueryKeys.ALL_USERS] })
            toast({
                title: t('success_title'),
                description: 'User invited successfully',
            })
        },
        onError: () => {
            queryClient.invalidateQueries({ queryKey: [QueryKeys.ALL_USERS] })
            navigate({ to: '../' })
            // sometimes sync after successful invite can fail, hiding the error message for now since the user will get invited
            // toast({
            //     title: t('error_title'),
            //     description: t('error_description_generic'),
            //     variant: 'destructive',
            // })
        },
    })

    const submitInvite = async (data: any) => {
        try {
            await inviteUserMutation.mutateAsync(data)
            navigate({ to: '../' })
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <form onSubmit={handleSubmit(submitInvite)} className="flex flex-col gap-6">
            <div className="flex flex-col gap-2">
                <Label>First Name</Label>
                <Input
                    {...register('firstName', { required: true })}
                    id="firstName"
                    placeholder="Full Name"
                />
            </div>
            <div className="flex flex-col gap-2">
                <Label>Last Name</Label>
                <Input
                    {...register('lastName', { required: true })}
                    id="lastName"
                    placeholder="Full Name"
                />
            </div>
            <div className="flex flex-col gap-2">
                <Label>Email</Label>
                <Input
                    {...register('email', { required: true })}
                    type="email"
                    id="email"
                    placeholder="Email"
                />
            </div>
            <DialogFooter>
                <Button
                    disabled={
                        !formState.isValid || !formState.isDirty || inviteUserMutation.isPending
                    }
                    className="bg-primary-legal hover:bg-[#5C33FFDD] font-bold leading-6 font-normal text-[13px]"
                >
                    {inviteUserMutation.isPending ? <p>Sending...</p> : <p>Send Invite</p>}
                </Button>
            </DialogFooter>
        </form>
    )
}
