import React, { useEffect } from 'react'
import { Button } from '@/components/ui/button.tsx'
import { formatCurrency } from '@/lib/utils.ts'
import useTenantQuery from '@/hooks/use-tenant-query.ts'
import { Loader2 } from 'lucide-react'

interface AccountSummaryProps {
    onAddFundsClick: () => void
}

export const LOW_BALANCE_THRESHOLD = 5

const AccountSummary: React.FC<AccountSummaryProps> = ({ onAddFundsClick }) => {
    const [balance, setBalance] = React.useState(0)
    const [lowBalance, setLowBalance] = React.useState(false)
    const { details, isLoading } = useTenantQuery()

    useEffect(() => {
        if (!isLoading && details) {
            setBalance(details.balance)
            setLowBalance(details.balance <= LOW_BALANCE_THRESHOLD)
        }
    }, [details, isLoading])

    return (
        <div className={'flex justify-between items-center'}>
            <div className={'flex flex-col jusify-center gap-4'}>
                <h1>Account Balance</h1>
                {!isLoading ? (
                    <span
                        className={`${lowBalance ? 'text-destructive' : 'text-primary'} text-5xl`}
                    >
                        {formatCurrency(balance)}
                    </span>
                ) : (
                    <span>
                        <Loader2 className={'animate-spin h-3xl w-3xl'} />
                    </span>
                )}
            </div>
            <div className={'flex items-center'}>
                <Button onClick={onAddFundsClick} className={'font-medium'}>
                    Manage Account
                </Button>
            </div>
        </div>
    )
}

export default AccountSummary
