import {
    LICENSE_FRIENDLY_NAMES,
    LibraryModel,
    LicenseKey,
    getPaginatedLibraryModels,
} from '@/api/library.ts'
import ClipboardCheck from '@/assets/icons/clipboard-check.svg?react'
import DataFlow from '@/assets/icons/dataflow.svg?react'
import User from '@/assets/icons/user.svg?react'
import ModelLibrary from '@/assets/model-library.svg?react'
import MegaSearch from '@/components/megaSearch/MegaSearch.tsx'
import ModelLibraryCard from '@/components/modelLibrary/ModelLibraryCard.tsx'
import ProviderIcon from '@/components/modelLibrary/ProviderIcon.tsx'
import NewProjectForm from '@/components/newProject/newProjectForm.tsx'
import SelectProjectForm from '@/components/modelLibrary/selectProjectForm.tsx'
import PageBanner from '@/components/pageBanner/pageBanner.tsx'
import { Button } from '@/components/ui/button.tsx'
import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog.tsx'
import { Separator } from '@/components/ui/separator.tsx'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs.tsx'
import { TabsContent } from '@radix-ui/react-tabs'
import { useRouter } from '@tanstack/react-router'
import { ColumnDef } from '@tanstack/react-table'
import { Check, LayoutGrid, List, X } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from '@/lib/i18n'
import { FeedDataTable } from '../home/feed/feedDataTable/FeedDataTable'
import { Badge } from '../ui/badge'
import { cn } from '@/lib/utils'
import { useQuery } from '@tanstack/react-query'
import { paginationAndSortingParams } from '../pagination/paginationHeader'
import { QueryKeys } from '@/constants/QueryKeys'

interface ModelLibraryProps {
    queryParams: paginationAndSortingParams
}

export function ModelLibraryView({ queryParams }: ModelLibraryProps) {
    const storageKey = 'model-library-banner'
    const { t } = useTranslation()
    const router = useRouter()
    const [projectDialogOpen, setProjectDialogOpen] = useState<boolean>(false)
    const [activeModel, setActiveModel] = useState<LibraryModel | undefined>(undefined)
    const [bannerVisible, setBannerVisible] = useState<boolean>(
        localStorage.getItem(storageKey) != 'false'
    )
    const [dialogMode, setDialogMode] = useState<'new' | 'existing' | 'desc' | 'filter'>('new')

    const ModelLibraryQuery = useQuery({
        queryKey: [QueryKeys.PAGINATED_LIBRARY_MODELS, queryParams],
        queryFn: () =>
            getPaginatedLibraryModels(
                queryParams.pageSize && queryParams.pageNumber
                    ? { ...queryParams }
                    : { pageSize: 25, pageNumber: 1 }
            ),
    })

    const onDialogOpen = async (
        open: boolean,
        mode: 'new' | 'existing' | 'desc' | 'filter',
        newActiveModel?: LibraryModel,
        isProjectSet?: boolean
    ) => {
        if (queryParams.projectId && isProjectSet) {
            await router.navigate({
                to: '/$projectId/models/new',
                params: { projectId: queryParams.projectId },
                search: { libraryModelId: newActiveModel?.id },
            })
        }
        setDialogMode(mode)
        setActiveModel(newActiveModel)
        setProjectDialogOpen(open)
    }

    const onBannerButtonClick = () => {
        localStorage.setItem(storageKey, 'false')
        setBannerVisible(false)
    }

    const onSelectProjectSubmit = async (projectId: string) => {
        await router.navigate({
            to: '/$projectId/models/new',
            params: { projectId },
            search: { libraryModelId: activeModel?.id },
        })
    }

    const openInNewTab = () => {
        window.open(
            'https://airia.com/explore/platform/Configuration/models/#supported-endpoints',
            '_blank'
        )
    }

    const columns: ColumnDef<LibraryModel>[] = [
        {
            header: 'Name',
            accessorKey: 'displayName',
            cell: ({ row }) => {
                return (
                    <div className="flex flex-col gap-1 min-w-72 max-w-[25vw]">
                        <p className="font-medium text-sm text-gray-550">
                            {row.original.displayName}
                        </p>
                        <p
                            title={row.original.description}
                            className="text-sm text-gray-450 truncate"
                        >
                            {row.original.description}
                        </p>
                    </div>
                )
            },
        },
        {
            header: 'Provider',
            accessorKey: 'provider',
            cell: ({ row }) => (
                <div className="flex gap-1 items-center">
                    <ProviderIcon provider={row.original.provider} className={'h-14 w-14'} />
                    {row.original.provider}
                </div>
            ),
            enableSorting: false,
        },
        {
            header: 'Author',
            accessorKey: 'author',
        },
        {
            header: 'Category',
            accessorKey: 'category',
            enableSorting: false,
        },
        {
            header: 'Model ID',
            accessorKey: 'name',
        },
        {
            header: 'Input Cost (USD / 1k tokens)',
            accessorKey: 'inputTokenPrice',
            cell: ({ row }) => (
                <span>{`${row.original.tokenPriceConversion.inputTokenPrice1K}`}</span>
            ),
        },
        {
            header: 'Output Cost (USD / 1k tokens)',
            accessorKey: 'outputTokenPrice',
            cell: ({ row }) => (
                <span>{`${row.original.tokenPriceConversion.outputTokenPrice1K}`}</span>
            ),
        },
        {
            header: 'Airia Key Support',
            accessorKey: 'allowAiriaCredentials',
            cell: ({ row }) =>
                row.original.allowAiriaCredentials ? (
                    <div className="flex gap-1 items-center text-success">
                        <Check className="w-3 h-3" />
                        <p>Yes</p>
                    </div>
                ) : (
                    <div className="flex gap-1 items-center text-error">
                        <X className="w-3 h-3" />
                        <p>No</p>
                    </div>
                ),
        },
        {
            header: 'Custom Key Support',
            accessorKey: 'allowAiriaCredentials',
            cell: ({ row }) =>
                row.original.allowBYOKCredentials ? (
                    <div className="flex gap-1 items-center text-success">
                        <Check className="w-3 h-3" />
                        <p>Yes</p>
                    </div>
                ) : (
                    <div className="flex gap-1 items-center text-error">
                        <X className="w-3 h-3" />
                        <p>No</p>
                    </div>
                ),
        },
        {
            header: 'Languages',
            accessorKey: 'languages',
            cell: ({ row }) => {
                const visibleLanguages = row.original.languages.slice(0, 2)
                const additionalLanguages = row.original.languages.slice(
                    2,
                    row.original.languages.length
                )
                return (
                    <div title={row.original.languages.join(', ')} className={'flex'}>
                        {visibleLanguages.join(', ')}
                        {additionalLanguages.length > 0 && (
                            <span>, +{additionalLanguages.length}</span>
                        )}
                    </div>
                )
            },
        },
        {
            header: 'License',
            accessorKey: 'licenseType',
            enableSorting: false,
            cell: ({ row }) => {
                return (
                    LICENSE_FRIENDLY_NAMES[row.original.licenseType as LicenseKey] ??
                    LICENSE_FRIENDLY_NAMES.Unlicense
                )
            },
        },
        {
            id: 'tags',
            header: 'Tags',
            cell: ({ row }) => {
                return (
                    <div className="flex flex-row content-center items-center gap-2 flex-wrap min-w-56">
                        {row.original.tags.slice(0, 2).map(
                            (tag) =>
                                tag !== '' && (
                                    <Badge
                                        key={tag}
                                        title={tag}
                                        className="rounded-lg bg-gray-200 text-gray-450 text-xs hover:text-gray-450 cursor-default line-clamp-1"
                                    >
                                        {tag}
                                    </Badge>
                                )
                        )}
                        {row.original.tags.length > 2 && (
                            <Badge
                                title={row.original.tags.slice(2).join(', ')}
                                className="rounded-lg bg-gray-200 text-gray-450 text-xs hover:text-gray-450 cursor-default"
                            >
                                {`+${row.original.tags.length - 2}`}
                            </Badge>
                        )}
                    </div>
                )
            },
        },
    ]

    return (
        <div>
            {bannerVisible && (
                <PageBanner
                    onButtonClick={onBannerButtonClick}
                    title={'Find the perfect model for your project.'}
                    icon={<ModelLibrary />}
                />
            )}
            <div className={'px-6 h-full'}>
                <Tabs className={'flex flex-col gap-5'} defaultValue={'list'}>
                    <div className={'mt-6 px-[15%]'}>
                        <MegaSearch
                            placeholder={'Search by Model Name'}
                            serverSide
                            search={queryParams}
                        />
                    </div>
                    <div className={'flex items-center self-end'}>
                        <TabsList>
                            <TabsTrigger value="list">
                                <List size={18} />
                            </TabsTrigger>
                            <TabsTrigger value="cards">
                                <LayoutGrid size={18} />
                            </TabsTrigger>
                        </TabsList>
                    </div>
                    <div className={'gap-6 pb-10'}>
                        <div className={'col-span-3 2xl:col-span-4'}>
                            <TabsContent value={'cards'}>
                                <div className={'grid gap-4 grid-cols-3 xl:grid-cols-4'}>
                                    {ModelLibraryQuery?.data?.models.map((model) => {
                                        return (
                                            <ModelLibraryCard
                                                key={model.id}
                                                model={model}
                                                onClick={() => onDialogOpen(true, 'desc', model)}
                                            />
                                        )
                                    })}
                                </div>
                            </TabsContent>
                            <TabsContent
                                className={cn(
                                    'h-[calc(100vh-290px)]',
                                    bannerVisible && 'h-[calc(100vh-620px)]'
                                )}
                                value={'list'}
                            >
                                <FeedDataTable
                                    onRowClick={(row: { original: LibraryModel }) => {
                                        onDialogOpen(true, 'desc', row.original)
                                    }}
                                    columns={columns}
                                    data={ModelLibraryQuery?.data?.models ?? []}
                                    totalCount={ModelLibraryQuery?.data?.totalCount ?? 25}
                                    initialSortBy="displayName"
                                    initialSortDirection="ASC"
                                />
                            </TabsContent>
                        </div>
                    </div>
                </Tabs>
                <Dialog open={projectDialogOpen} onOpenChange={setProjectDialogOpen}>
                    <DialogContent className={'min-w-[750px]'}>
                        <DialogHeader>
                            {dialogMode === 'new' ? (
                                t('create_with_model')
                            ) : dialogMode === 'existing' ? (
                                t('add_to_project')
                            ) : (
                                <div className={'flex gap-4 items-center'}>
                                    <ProviderIcon
                                        provider={activeModel?.provider ?? ''}
                                        className={'h-14 w-14'}
                                    />
                                    <div className={'flex flex-col'}>
                                        {activeModel?.name}
                                        <span
                                            className={'flex items-center text-foreground text-sm'}
                                        >
                                            <User className={'h-4 w-4 inline-block mr-1'} />
                                            {activeModel?.provider}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </DialogHeader>
                        {dialogMode === 'new' ? (
                            <NewProjectForm onSuccess={onSelectProjectSubmit} />
                        ) : dialogMode === 'existing' ? (
                            <SelectProjectForm onSelectProjectSubmit={onSelectProjectSubmit} />
                        ) : dialogMode === 'desc' ? (
                            <>
                                {activeModel && (
                                    <div>
                                        <div className={'flex gap-2'}>
                                            <div
                                                className={
                                                    'border border-border px-2 py-1 rounded-sm'
                                                }
                                            >
                                                {activeModel.tokenPriceConversion.inputTokenPrice1K}{' '}
                                                <span className={'text-xs'}>
                                                    $ / 1k input tokens
                                                </span>
                                            </div>
                                            <div
                                                className={
                                                    'border border-border px-2 py-1 rounded-sm'
                                                }
                                            >
                                                {
                                                    activeModel.tokenPriceConversion
                                                        .outputTokenPrice1K
                                                }{' '}
                                                <span className={'text-xs'}>
                                                    $ / 1k output tokens
                                                </span>
                                            </div>
                                        </div>
                                        <div className={'pt-4'}>
                                            <span className={'text-foreground'}>
                                                {activeModel.description}
                                            </span>
                                        </div>
                                    </div>
                                )}
                                <div className="text-foreground text-[13px]">
                                    By adding this model to a project, you agree to abide by all
                                    applicable Third-Party Services terms, including:&nbsp;
                                    {activeModel?.licenseLink !== '' ? (
                                        <a
                                            href={activeModel?.licenseLink}
                                            className="text-primary flex flex-row items-center gap-1"
                                            target="_blank"
                                        >
                                            {
                                                LICENSE_FRIENDLY_NAMES[
                                                    activeModel?.licenseType as LicenseKey
                                                ]
                                            }
                                            .
                                        </a>
                                    ) : (
                                        LICENSE_FRIENDLY_NAMES[
                                            activeModel?.licenseType as LicenseKey
                                        ]
                                    )}
                                </div>
                                <Separator />
                                <div className={'flex justify-end gap-4'}>
                                    {queryParams.projectId === undefined && (
                                        <Button
                                            variant={'secondary'}
                                            onClick={() =>
                                                activeModel &&
                                                onDialogOpen(true, 'new', activeModel)
                                            }
                                        >
                                            <div className={'flex items-center gap-2.5'}>
                                                <span>Use In New Project</span>
                                                <DataFlow className={'w-4 h-4'} />
                                            </div>
                                        </Button>
                                    )}
                                    <Button
                                        onClick={() =>
                                            activeModel &&
                                            onDialogOpen(
                                                true,
                                                'existing',
                                                activeModel,
                                                queryParams.projectId !== undefined
                                            )
                                        }
                                    >
                                        <div className={'flex items-center gap-2.5'}>
                                            <span>Add To Project</span>
                                            <ClipboardCheck className={'w-4 h-4'} />
                                        </div>
                                    </Button>
                                </div>
                            </>
                        ) : null}
                    </DialogContent>
                </Dialog>
            </div>
            <footer className="fixed bottom-0 left-0 w-full bg-white py-3 text-center text-sm border-t border-gray-300 shadow-lg">
                Have another model you want to work with?
                <Button
                    variant={'link'}
                    onClick={() => {
                        onDialogOpen(
                            true,
                            'existing',
                            activeModel,
                            queryParams.projectId !== undefined
                        )
                    }}
                    className="text-primary !p-1"
                >
                    <strong>Add a model</strong>
                </Button>
                or find more details of our
                <Button variant={'link'} onClick={openInNewTab} className="text-primary !p-1">
                    <strong> supported providers and endpoints.</strong>
                </Button>
            </footer>
        </div>
    )
}
