import type { ColumnDef } from '@tanstack/react-table'
import { RefreshCw } from 'lucide-react'
import { Button } from '../ui/button'
import CompoundFilter from './CompoundFilter'
import { useEffect, useState } from 'react'
import { Dialog, DialogContent, DialogFooter, DialogHeader } from '../ui/dialog'
import ExportForm from '../home/consumption/ExportForm'
import { useQueryClient } from '@tanstack/react-query'
import type { QueryKeys } from '@/constants/QueryKeys'
import { toast } from '../ui/use-toast'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { subDays, subMonths, startOfDay } from 'date-fns'
import { DatePicker } from '../ui/date-picker'
import { useDebounce } from '@uidotdev/usehooks'
import FeedExport from './FeedExport'

export type paginationAndSortingParams = {
    projectId?: string
    pageNumber: number
    pageSize: number
    sortBy?: string
    sortDirection?: 'ASC' | 'DESC'
    filter?: string
    startDate?: string
    endDate?: string
}

type PaginationProps = {
    queryParams: paginationAndSortingParams
    totalCount?: number
}

export interface FeedHeaderProps<T> extends PaginationProps {
    sortOptions?: ColumnDef<T>[]
    headerTitle: string
    additionalFilteringParameters?: CompoundFilterParameters[]
    additionalPrepopulatedValues?: { column: string; values: string[] }[]
    totalCount?: number
    refreshQueryKeys: (QueryKeys | paginationAndSortingParams | string)[]
    type: 'ingestion' | 'gateway' | 'execution' | 'consumption' | 'processing'
}

export type AdvancedCompoundFilterParameters = {
    id: string
    column: string
    condition: string
    type: string
}

export type CompoundFilterParameters = {
    column: string
    internalColumn: string
    value: string[]
    multiple?: boolean
    customValues?: { key: string; value: string }[]
    valueFormatter?: (values: string[] | boolean[]) => string[] | boolean[]
}

const FeedHeader = <T,>({
    headerTitle,
    additionalFilteringParameters,
    additionalPrepopulatedValues,
    queryParams,
    totalCount,
    refreshQueryKeys,
    type,
}: FeedHeaderProps<T>) => {
    const navigate = useNavigate()
    const search = useSearch({ strict: false }) as paginationAndSortingParams
    const [openCustomDate, setOpenCustomDate] = useState(false)
    // const [searchValue, setSearchValue] = useState<string>('')
    // const debouncedSearchValue = useDebounce(searchValue, 500)

    const [filteringParameters, setFilteringParameters] = useState<CompoundFilterParameters[]>(
        additionalFilteringParameters
            ? [
                  {
                      column: 'Date',
                      internalColumn: 'createdAt',
                      value: [],
                      customValues: [
                          { key: 'startDate', value: '' },
                          { key: 'endDate', value: '' },
                      ],
                  },
                  { column: 'Project', internalColumn: 'projectId', value: [], multiple: true },
                  ...additionalFilteringParameters,
              ]
            : [
                  {
                      column: 'Date',
                      internalColumn: 'createdAt',
                      value: [],
                      customValues: [
                          { key: 'startDate', value: '' },
                          { key: 'endDate', value: '' },
                      ],
                  },
                  { column: 'Project', internalColumn: 'projectId', value: [], multiple: true },
              ]
    )
    const debouncedFilteringParameteres = useDebounce(filteringParameters, 500)
    const [exportOpen, setExportOpen] = useState(false)
    const queryClient = useQueryClient()
    const [customDateRange, setCustomDateRange] = useState<{ startDate?: Date; endDate?: Date }>()

    const getPrePopulatedValues = (column: string) => {
        switch (column) {
            case 'Date':
                return [
                    'Last 1 Day',
                    'Last 7 days',
                    'Last 14 days',
                    'Current Month',
                    'Previous Month',
                    'Last 6 Months',
                    'Current Year',
                    'Previous Year',
                    'Custom',
                ]
            default: {
                const values = additionalPrepopulatedValues?.find(
                    (param) => param.column === column
                )?.values
                return values ?? []
            }
        }
    }

    const getDateRange = (value: string) => {
        const today = new Date()

        switch (value) {
            case 'Last 1 Day':
                return { startDate: startOfDay(subDays(today, 1)), endDate: today }
            case 'Last 7 days':
                return { startDate: startOfDay(subDays(today, 7)), endDate: today }
            case 'Last 14 days':
                return { startDate: startOfDay(subDays(today, 14)), endDate: today }
            case 'Current Month':
                return {
                    startDate: startOfDay(new Date(today.getFullYear(), today.getMonth(), 1)),
                    endDate: today,
                }
            case 'Previous Month':
                return {
                    startDate: startOfDay(new Date(today.getFullYear(), today.getMonth() - 1, 1)),
                    endDate: new Date(today.getFullYear(), today.getMonth(), 0),
                }
            case 'Last 6 Months':
                return { startDate: startOfDay(subMonths(today, 6)), endDate: today }
            case 'Current Year':
                return {
                    startDate: startOfDay(new Date(today.getFullYear(), 0, 1)),
                    endDate: today,
                }
            case 'Previous Year':
                return {
                    startDate: startOfDay(new Date(today.getFullYear() - 1, 0, 1)),
                    endDate: new Date(today.getFullYear() - 1, 11, 31),
                }
            default:
                return { startDate: null, endDate: null }
        }
    }

    const refresh = async () => {
        await queryClient.invalidateQueries({
            queryKey: refreshQueryKeys,
        })
    }

    const applyCustomDateRange = () => {
        if (
            (customDateRange?.startDate && customDateRange?.endDate) ||
            (search.startDate && search.endDate)
        ) {
            setFilteringParameters((prev) => {
                return prev.map((param) => {
                    if (param.column === 'Date') {
                        param.value = ['Custom']
                        if (param.customValues && param?.customValues?.length > 0) {
                            param.customValues.map((customValue) => {
                                if (customValue.key === 'startDate') {
                                    customValue.value =
                                        customDateRange?.startDate?.toISOString() ??
                                        search.startDate ??
                                        ''
                                } else if (customValue.key === 'endDate') {
                                    customValue.value =
                                        customDateRange?.endDate?.toISOString() ??
                                        search.endDate ??
                                        ''
                                }
                                return customValue
                            })
                        } else {
                            param.customValues = [
                                {
                                    key: 'startDate',
                                    value:
                                        customDateRange?.startDate?.toISOString() ??
                                        search.startDate ??
                                        '',
                                },
                                {
                                    key: 'endDate',
                                    value:
                                        customDateRange?.endDate?.toISOString() ??
                                        search.endDate ??
                                        '',
                                },
                            ]
                        }
                    }
                    return param
                })
            })
            setOpenCustomDate(false)
        } else {
            toast({
                title: 'Error',
                description: 'Please select a valid date range.',
                variant: 'destructive',
            })
        }
    }

    const clearCustomDateRange = () => {
        setCustomDateRange(undefined)
        setFilteringParameters((prev) => {
            return prev.map((param) => {
                if (param.column === 'Date') {
                    param.value = []
                    param.customValues?.map((customValue) => {
                        customValue.value = ''
                        return customValue
                    })
                }
                return param
            })
        })
        setOpenCustomDate(false)
    }

    const countFilters = (filters: CompoundFilterParameters[]) => {
        let count = 0
        filters?.forEach((param) => {
            if (param.value.length > 0) {
                count += param.value.length
            }
        })
        return count
    }

    useEffect(() => {
        const newSearch: Record<string, string | number | boolean> = {
            ...search,
            // search: debouncedSearchValue,
        }

        debouncedFilteringParameteres?.forEach((param) => {
            if (param.column === 'Date' && param.value.length > 0) {
                if (param.value[0] === 'Custom') {
                    param.customValues?.forEach((customValue) => {
                        if (customValue.value !== '') {
                            newSearch[customValue.key] = customValue.value
                        }
                    })
                } else {
                    const { startDate, endDate } = getDateRange(param.value[0])
                    if (startDate && endDate) {
                        newSearch.startDate = startDate.toISOString()
                        newSearch.endDate = endDate.toISOString()
                    }
                }
            } else if (!param.multiple && param.value.length > 0) {
                if (param.valueFormatter) {
                    newSearch[param.internalColumn] = param.valueFormatter(param.value)[0]
                } else {
                    newSearch[param.internalColumn] = param.value[0]
                }
            } else if (param.value.length > 0) {
                if (param.valueFormatter) {
                    newSearch[param.internalColumn] = param.valueFormatter(param.value).join(',')
                } else {
                    newSearch[param.internalColumn] = param.value.join(',')
                }
            } else {
                if (param.column === 'Date' && param.value[0] !== 'Custom') {
                    delete newSearch.startDate
                    delete newSearch.endDate
                }
                delete newSearch[param.internalColumn]
            }
        })

        // if (debouncedSearchValue === '') {
        //     delete newSearch.search
        // }

        navigate({
            search: newSearch,
        })
    }, [debouncedFilteringParameteres, search, navigate])

    useEffect(() => {
        if (search) {
            const temp = filteringParameters.map((param) => {
                const value = (search as Record<string, string | number>)[param.internalColumn]
                if (param.column === 'Date') {
                    const startDate = (search as Record<string, string | number>)?.startDate
                    const endDate = (search as Record<string, string | number>)?.endDate
                    if (startDate && endDate) {
                        return {
                            column: param.column,
                            internalColumn: param.internalColumn,
                            value: ['Custom'],
                            multiple: param.multiple || false,
                        }
                    }
                } else if (value) {
                    return {
                        column: param.column,
                        internalColumn: param.internalColumn,
                        value: (value as string).split(','),
                        multiple: param.multiple || false,
                    }
                }
                return param
            })

            setFilteringParameters(temp)
        }
    }, [])

    return (
        <>
            <div className="flex flex-col gap-lg py-lg">
                <p className="text-2xl text-foreground font-bold">{headerTitle}</p>
                <div className="flex px-1 items-center gap-2 justify-end">
                    {/*<Input*/}
                    {/*    startIcon={Search}*/}
                    {/*    className="col-span-1"*/}
                    {/*    placeholder="Search"*/}
                    {/*    value={searchValue}*/}
                    {/*    onChange={(e) => setSearchValue(e.target.value)}*/}
                    {/*/>*/}
                    <div className="flex flex-row items-center gap-1 col-span-1 justify-end text-primary hover:text-primary">
                        <Button
                            variant="ghost"
                            className="flex items-center rounded-full gap-2 hover:bg-primary-muted hover:text-primary"
                            onClick={() => refresh()}
                        >
                            <RefreshCw className="w-4 h-4" />
                            Refresh
                        </Button>
                        {/* <a href="https://airia.com/explore" target="_blank" rel="noreferrer">
                            <Button
                                type="button"
                                variant="ghost"
                                className=" hover:bg-primary-muted rounded-full hover:text-primary flex items-center gap-2"
                            >
                                <HelpCircle className="w-4 h-4" /> Help
                            </Button>
                        </a> */}
                        <FeedExport queryParams={queryParams} type={type} />
                        {/* <Button
                            variant="ghost"
                            className="hover:bg-primary-muted rounded-full hover:text-primary flex items-center gap-2"
                            onClick={() => window.print()}
                        >
                            <Printer className="w-4 h-4" />
                            Print
                        </Button> */}
                    </div>
                </div>
                <div className="flex flex-row flex-wrap gap-2 px-1">
                    {filteringParameters?.map((param) => (
                        <CompoundFilter
                            key={param.column}
                            filteringParamters={filteringParameters}
                            setFilteringParameters={setFilteringParameters}
                            column={param.column}
                            prePopulatedValues={getPrePopulatedValues(param.column)}
                            handleCustom={
                                param.column === 'Date' ? () => setOpenCustomDate(true) : undefined
                            }
                        />
                    ))}
                </div>
            </div>
            <Dialog open={exportOpen} onOpenChange={setExportOpen}>
                <DialogContent className={'min-w-[850px]'}>
                    <DialogHeader>
                        <div>Export</div>
                    </DialogHeader>
                    <ExportForm
                        queryParams={queryParams}
                        filterCount={countFilters(filteringParameters) ?? 0}
                        totalCount={totalCount ?? 0}
                        type={type}
                    />
                </DialogContent>
            </Dialog>
            <Dialog open={openCustomDate} onOpenChange={setOpenCustomDate}>
                <DialogContent className={'max-w-modal-sm p-lg'}>
                    <DialogHeader>
                        <p className="font-medium text-2xl text-gray-550">Custom Date Range</p>
                    </DialogHeader>
                    <div className="flex flex-col gap-md justify-center">
                        <div className="flex flex-col gap-1">
                            <p className="text-base text-gray-450">Start Date</p>
                            <DatePicker
                                className="w-full"
                                onDateSelect={(date) => {
                                    setCustomDateRange({ ...customDateRange, startDate: date })
                                }}
                                defaultDate={
                                    search.startDate ? new Date(search.startDate) : undefined
                                }
                            />
                        </div>
                        <div className="flex flex-col gap-1">
                            <p className="text-base text-gray-450">End Date</p>
                            <DatePicker
                                className="w-full"
                                onDateSelect={(date) => {
                                    setCustomDateRange({ ...customDateRange, endDate: date })
                                }}
                                defaultDate={search.endDate ? new Date(search.endDate) : undefined}
                            />
                        </div>
                    </div>
                    <DialogFooter className="-mb-5 self-end">
                        <Button
                            onClick={() => clearCustomDateRange()}
                            type="button"
                            variant="secondary"
                            className="w-[100px] "
                        >
                            Clear
                        </Button>
                        <Button
                            onClick={() => applyCustomDateRange()}
                            type="button"
                            className="w-[100px]"
                        >
                            Apply
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default FeedHeader
