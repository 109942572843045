import { User, getAllUsers, syncUsers } from '@/api/users'
import { DefaultDataTable } from '@/components/DefaultDataTable'
import UserCard from '@/components/legal/users/UserCard'
import { Button } from '@/components/ui/button'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { toast } from '@/components/ui/use-toast'
import { QueryKeys } from '@/constants/QueryKeys'
import { useAuth } from '@/hooks/use-auth'
import { useConfidentiality } from '@/hooks/use-confidentiality'
import { cn } from '@/lib/utils'
import { TabsContent } from '@radix-ui/react-tabs'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Link, Outlet, createFileRoute, useNavigate } from '@tanstack/react-router'
import { ColumnDef } from '@tanstack/react-table'
import { t } from 'i18next'
import {
    LayoutGrid,
    List,
    MoreHorizontal,
    Pencil,
    RotateCcw,
    Trash,
    User as UserIcon,
} from 'lucide-react'

export const Route = createFileRoute('/_legalLayout/$projectId/legal/users')({
    component: () => <Users />,
})

function Users() {
    const { isConfidential } = useConfidentiality()
    const params = Route.useParams()
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const { isLegalAdmin, user: loggedInUser } = useAuth()
    const GetAllUsersQuery = useQuery({
        queryKey: [QueryKeys.ALL_USERS],
        queryFn: () => getAllUsers(undefined, true),
    })

    const handleRowClick = (row: { original: User }) => {
        if (!canEditUser(row.original)) return
        navigate({
            to: '/$projectId/legal/users/$userId/edit',
            params: {
                userId: row.original.identityServerUserId,
                projectId: params.projectId,
            },
        })
    }

    const syncUsersMutation = useMutation({
        mutationFn: syncUsers,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [QueryKeys.ALL_USERS] })
            toast({
                title: t('success_title'),
                description: 'Users refreshed.',
            })
        },
        onError: () => {
            toast({
                title: t('error_title'),
                description: t('error_description_generic'),
                variant: 'destructive',
            })
        },
    })

    const canEditUser = (user: User) => {
        return (
            isLegalAdmin &&
            user.identityServerUserId !== loggedInUser?.identityServerUserId &&
            user.id !== loggedInUser?.identityServerUserId
        )
    }

    const columns: ColumnDef<User>[] = [
        {
            header: 'Name',
            accessorKey: 'firstName',
            cell: ({ row }) => {
                return (
                    <div className="flex flex-row items-center gap-2">
                        <div className="h-8 w-8 bg-gray-200 rounded-full flex items-center justify-center">
                            <UserIcon className="w-5 h-5 text-black" />
                        </div>
                        <p>{`${row.original.firstName} ${row.original.lastName}`}</p>
                    </div>
                )
            },
        },
        {
            header: 'Email',
            accessorKey: 'email',
        },
        {
            id: 'actions',
            enableHiding: false,
            cell: ({ row }) => {
                if (canEditUser(row.original)) {
                    return (
                        <div className="justify-end flex pr-5">
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <Button
                                        variant="ghost"
                                        className="h-8 w-8 p-0 hover:text-black"
                                    >
                                        <span className="sr-only fixed">{t('open_menu')}</span>
                                        <MoreHorizontal className="h-4 w-4" />
                                    </Button>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent align="end">
                                    <DropdownMenuLabel className="flex gap-1 ">
                                        {t('actions')}
                                    </DropdownMenuLabel>
                                    <Link
                                        to="/$projectId/legal/users/$userId/edit"
                                        params={{
                                            userId: row.original.identityServerUserId,
                                            projectId: params.projectId,
                                        }}
                                    >
                                        <DropdownMenuItem className="flex gap-1">
                                            <Pencil className="h-4 w-4" />
                                            Edit User
                                        </DropdownMenuItem>
                                    </Link>
                                    <Link
                                        to="/$projectId/legal/users/$userId/delete"
                                        params={{
                                            userId: row.original.identityServerUserId,
                                            projectId: params.projectId,
                                        }}
                                    >
                                        <DropdownMenuItem className="flex gap-1 text-red-500">
                                            <Trash className="h-4 w-4" />
                                            Delete User
                                        </DropdownMenuItem>
                                    </Link>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </div>
                    )
                }
            },
        },
    ]

    return (
        <div
            className={cn(
                'flex flex-col gap-2 bg-background/75 shadow-md rounded-md p-5',
                isConfidential ? 'h-[calc(100vh-120px)]' : 'h-[calc(100vh-140px)]'
            )}
        >
            <Tabs defaultValue="cards">
                <div className="flex flex-row justify-between items-center p-2">
                    <p className="text-2xl font-medium font-normal leading-[normal]">Users</p>
                    <div className="flex flex-row items-center gap-2">
                        <TabsList className="justify-center align-center items-center bg-transparent">
                            <TabsTrigger
                                className="data-[state=active]:text-primary-legal data-[state=active]:bg-background p-2 rounded-xl"
                                value="cards"
                            >
                                <LayoutGrid size={18} />
                            </TabsTrigger>
                            <TabsTrigger
                                className="data-[state=active]:text-primary-legal data-[state=active]:bg-background p-2 rounded-xl"
                                value="table"
                            >
                                <List size={18} />
                            </TabsTrigger>
                        </TabsList>
                        {isLegalAdmin && (
                            <>
                                <Button
                                    type="button"
                                    variant="secondary"
                                    disabled={syncUsersMutation.isPending}
                                    className="bg-white hover:bg-white/75 text-primary-legal font-bold leading-6 font-normal text-[13px] rounded-lg"
                                    onClick={async () => {
                                        await syncUsersMutation.mutate()
                                        await queryClient.invalidateQueries({
                                            queryKey: [QueryKeys.ALL_USERS],
                                        })
                                    }}
                                >
                                    <RotateCcw className="w-5 h-5" />
                                </Button>
                                <Button
                                    type="button"
                                    className="bg-primary-legal hover:bg-primary-legal font-bold leading-6 font-normal text-[13px] rounded-lg"
                                    onClick={() =>
                                        navigate({
                                            to: '/$projectId/legal/users/new',
                                            params: { projectId: params.projectId },
                                        })
                                    }
                                >
                                    Add Users
                                </Button>
                            </>
                        )}
                    </div>
                </div>
                <TabsContent value="cards">
                    <div
                        className={cn(
                            'flex flex-col gap-2',
                            isConfidential ? 'h-[calc(100vh-200px)]' : 'h-[calc(100vh-220px)]'
                        )}
                    >
                        <div className="h-full overflow-y-auto">
                            <div className="flex flex-row flex-wrap gap-3 justify-start py-1">
                                {GetAllUsersQuery?.data?.map(
                                    (user, index) =>
                                        user.firstName && (
                                            <div
                                                key={index}
                                                onClick={() => {
                                                    if (canEditUser(user)) {
                                                        navigate({
                                                            to: '/$projectId/legal/users/$userId/edit',
                                                            params: {
                                                                userId: user.identityServerUserId,
                                                                projectId: params.projectId,
                                                            },
                                                        })
                                                    }
                                                }}
                                                className="flex flex-col gap-2 mt-2 cursor-pointer"
                                            >
                                                <UserCard {...user} />
                                            </div>
                                        )
                                )}
                            </div>
                        </div>
                    </div>
                </TabsContent>
                <TabsContent className="py-2" value="table">
                    <DefaultDataTable
                        className="h-[calc(100vh-220px)]"
                        columns={columns}
                        data={GetAllUsersQuery?.data?.filter((user) => user.firstName) || []}
                        onRowClick={handleRowClick}
                    />
                </TabsContent>
            </Tabs>

            <Outlet />
        </div>
    )
}
