import { CreateDataset, Dataset } from '@/api/datasets'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { toast } from '@/components/ui/use-toast'
import { QueryKeys } from '@/constants/QueryKeys'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useRouter } from '@tanstack/react-router'
import { t } from 'i18next'
import { useForm } from 'react-hook-form'

export const CreateNewMatter = ({
    projectId,
    onCreate,
    dataSourceGroupId,
}: {
    projectId: string
    onCreate: () => void
    dataSourceGroupId: string
}): JSX.Element => {
    const queryClient = useQueryClient()
    const router = useRouter()
    const { register, handleSubmit } = useForm<{ name: string }>()

    const datasetMutation = useMutation({
        mutationFn: CreateDataset,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [QueryKeys.ALL_DATASETS] })
            toast({
                title: t('success_title'),
                description: 'Folder created successfully',
            })
        },
        onError: () => {
            toast({
                title: t('error_title'),
                description: t('error_description_generic'),
                variant: 'destructive',
            })
        },
    })

    const handleCreateDataset = async (data: { name: string }) => {
        try {
            const dataToSend = {
                databaseType: 'mongodb',
                dataSourceType: 'FileUpload',
                configuration: {
                    type: 'FileUpload',
                    defaultFilePattern: '',
                },
                projectId: projectId,
                chunkingConfig: {
                    strategyType: 'markdown',
                    chunkSize: 10000,
                    chunkOverlap: 0,
                },
                isUserSpecific: true,
                name: data.name,
                embeddingProvider: 'OpenAI',
                configurationJson: '{"type":"FileUpload","defaultFilePattern":""}',
                dataSourceGroupId,
            }

            //@ts-ignore
            await datasetMutation.mutateAsync(dataToSend as Dataset)
            await router.invalidate()
            onCreate()
        } catch (error: any) {
            if (error.status === 409) {
                toast({
                    title: t('error_title'),
                    description: error.message,
                    variant: 'destructive',
                })
            } else {
                toast({
                    title: t('error_title'),
                    description: t('error_description_generic'),
                    variant: 'destructive',
                })
            }
        }
    }

    return (
        <div>
            <form onSubmit={handleSubmit(handleCreateDataset)}>
                <div className="flex flex-col gap-6">
                    <div className="flex flex-col gap-2">
                        <p className="font-bold">Name your first matter for this client</p>
                        <Input id="name" {...register('name')} placeholder="Matter Name" />
                    </div>
                    <Button
                        type="submit"
                        className="bg-primary-legal hover:bg-[#5C33FFDD] font-bold leading-6 font-normal text-[13px]"
                    >
                        Create Matter
                    </Button>
                </div>
            </form>
        </div>
    )
}
