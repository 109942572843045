import { CreateDataSourceGroup, DataSourceGroup } from '@/api/dataSourceGroup'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogFooter, DialogHeader } from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { toast } from '@/components/ui/use-toast'
import { QueryKeys } from '@/constants/QueryKeys'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useRouter } from '@tanstack/react-router'
import { t } from 'i18next'
import { useForm } from 'react-hook-form'

export const NewClient = ({
    projectId,
    open,
    setOpen,
}: {
    projectId: string
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}): JSX.Element => {
    const queryClient = useQueryClient()
    const router = useRouter()
    const { register, handleSubmit } = useForm<{ name: string }>()

    const datasourceGroupMutation = useMutation({
        mutationFn: CreateDataSourceGroup,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [QueryKeys.ALL_DATASOURCE_GROUPS] })
            toast({
                title: t('success_title'),
                description: 'Folder created successfully',
            })
        },
        onError: () => {
            toast({
                title: t('error_title'),
                description: t('error_description_generic'),
                variant: 'destructive',
            })
        },
    })

    const handleCreateNewDataSourceGroup = async (data: { name: string }) => {
        try {
            const dataToSend = {
                projectId: projectId,
                name: data.name,
                description: '',
            }

            await datasourceGroupMutation.mutateAsync(dataToSend as DataSourceGroup)
            await router.invalidate()
            setOpen(false)
        } catch (error: any) {
            if (error.status === 409) {
                toast({
                    title: t('error_title'),
                    description: error.message,
                    variant: 'destructive',
                })
            } else {
                toast({
                    title: t('error_title'),
                    description: t('error_description_generic'),
                    variant: 'destructive',
                })
            }
        }
    }

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogContent className="max-w-modal-sm max-h-modal">
                <DialogHeader>Create Client</DialogHeader>
                <form onSubmit={handleSubmit(handleCreateNewDataSourceGroup)}>
                    <div className="flex flex-col gap-6">
                        <div className="flex flex-col gap-2">
                            <Label>Name</Label>
                            <Input id="name" {...register('name')} placeholder="Client Name" />
                        </div>
                        <DialogFooter>
                            <Button
                                type="submit"
                                className="bg-primary-legal hover:bg-[#5C33FFDD] font-bold leading-6 font-normal text-[13px]"
                            >
                                Create
                            </Button>
                        </DialogFooter>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    )
}
