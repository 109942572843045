import type { CompoundFilterParameters } from './FeedHeader'
import { useMemo, useState } from 'react'
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from '../ui/command'
import { ScrollArea } from '../ui/scroll-area'
import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from '@/constants/QueryKeys'
import { type Project, getAllProjects, getAllProjectsPaginated } from '@/api/projects'
import { Checkbox } from '../ui/checkbox'
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover'
import { Check, ChevronDown, ChevronUp } from 'lucide-react'
import { cn } from '@/lib/utils'
import { useInfiniteQueryCreator } from '../pipelineBuilder/itemDrawer/useInfiniteQueryCreator'
import ProjectComponent from './ProjectComponent'
import { useDebounce } from '@uidotdev/usehooks'
import { RenderInfiniteFilterItems } from './RenderInfiniteFilterItems'

export type CompoundFilterProps = {
    filteringParamters: CompoundFilterParameters[]
    setFilteringParameters: React.Dispatch<React.SetStateAction<CompoundFilterParameters[]>>
    column: string
    prePopulatedValues: string[]
    handleCustom?: () => void
}

const CompoundFilter = ({
    filteringParamters,
    setFilteringParameters,
    column,
    prePopulatedValues,
    handleCustom,
}: CompoundFilterProps) => {
    const allProjectsQuery = useQuery({
        queryKey: [QueryKeys.PROJECTS],
        queryFn: getAllProjects,
    })

    const InfiniteProjectsQuery = useInfiniteQueryCreator<Project>(
        QueryKeys.ALL_PROJECTS_INFINITE,
        getAllProjectsPaginated,
        undefined,
        'name',
        'asc'
    )

    const [openComboBox, setOpenComboBox] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')
    const debouncedSearchTerm = useDebounce(searchTerm, 500)

    const thisParam = useMemo(
        () =>
            filteringParamters.find((param) => param.column === column) ?? {
                column: '',
                value: [''],
                multiple: false,
            },
        [filteringParamters, column]
    )
    const handleSetValue = (val: string) => {
        setFilteringParameters((prev) => {
            const newFilteringParameters = prev.map((filteringParameter) => {
                if (filteringParameter.column === column) {
                    const checkVal = filteringParameter.value.includes(val)
                    if (checkVal) {
                        return {
                            ...filteringParameter,
                            value: filteringParameter.value.filter((value) => value !== val),
                        }
                    }
                    return {
                        ...filteringParameter,
                        value: [...filteringParameter.value, val],
                    }
                }
                return filteringParameter
            })
            return newFilteringParameters
        })
    }

    const handleSetSingleValue = (val: string) => {
        console.log(val)
        setFilteringParameters((prev) => {
            const newFilteringParameters = prev.map((filteringParameter) => {
                if (filteringParameter.column === column) {
                    const checkVal = filteringParameter.value.includes(val)
                    if (checkVal && (val !== 'Custom' || column === 'Credential Type')) {
                        return {
                            ...filteringParameter,
                            value: [],
                        }
                    }
                    return {
                        ...filteringParameter,
                        value: [val],
                    }
                }
                return filteringParameter
            })
            return newFilteringParameters
        })

        if (column === 'Date' && val === 'Custom') {
            if (handleCustom) {
                handleCustom()
            }
        }

        setOpenComboBox(false)
    }

    return (
        <div className="flex flex-row items-center">
            <div className="border-l-[1px] border-t-[1px] border-b-[1px] justify-center items-center flex py-[10px] px-3 h-9 rounded-l-md border-gray-250 text-sm text-gray-550 select-none">
                {column}
            </div>
            <Popover open={openComboBox} onOpenChange={setOpenComboBox}>
                <PopoverTrigger
                    className={cn(
                        'min-w-24 py-[10px] px-3 border-[1px] rounded-r-md border-gray-250 h-9 flex items-center select-none cursor-pointer',
                        thisParam.value.length === 0 && 'text-gray-350'
                    )}
                    asChild
                >
                    <div className="flex flex-row items-center justify-between gap-1">
                        {column === 'Project' ? (
                            <p className="text-sm">
                                {thisParam.value.length === 0
                                    ? `Any ${column}`
                                    : thisParam.value.length > 1
                                      ? `${
                                            allProjectsQuery?.data?.find(
                                                (project) => project.id === thisParam.value[0]
                                            )?.name
                                        }, + ${thisParam.value.length - 1}`
                                      : allProjectsQuery.data?.find(
                                            (project) => project.id === thisParam.value[0]
                                        )?.name}
                            </p>
                        ) : (
                            <p className="text-sm">
                                {thisParam.value.length === 0
                                    ? `Any ${column}`
                                    : thisParam.value.length > 1
                                      ? `${thisParam.value[0]}, + ${thisParam.value.length - 1}`
                                      : thisParam.value[0]}
                            </p>
                        )}
                        {openComboBox ? (
                            <ChevronUp className="w-4 h-4 text-gray-350" />
                        ) : (
                            <ChevronDown className="w-4 h-4 text-gray-350" />
                        )}
                    </div>
                </PopoverTrigger>
                <PopoverContent align="start" className="p-0">
                    {column === 'Project' ? (
                        <Command shouldFilter={false}>
                            <CommandInput
                                value={searchTerm}
                                onValueChange={setSearchTerm}
                                placeholder="Search project..."
                            />
                            <CommandList>
                                <ScrollArea>
                                    <CommandGroup className="">
                                        <RenderInfiniteFilterItems
                                            queryAndUpdateFilter={InfiniteProjectsQuery}
                                            ItemFilterComponent={ProjectComponent}
                                            thisParam={thisParam as CompoundFilterParameters}
                                            debouncedSearchTerm={debouncedSearchTerm}
                                            handleSetValue={handleSetValue}
                                        />
                                    </CommandGroup>
                                </ScrollArea>
                            </CommandList>
                        </Command>
                    ) : thisParam.multiple ? (
                        <Command
                            filter={(_, search, keywords) => {
                                if (
                                    keywords?.join(' ').toLowerCase().includes(search.toLowerCase())
                                ) {
                                    return 1
                                }
                                return 0
                            }}
                        >
                            <CommandInput placeholder={`Search ${column.toLocaleLowerCase()}...`} />
                            <CommandList>
                                <ScrollArea>
                                    <CommandEmpty>No filters found.</CommandEmpty>
                                    <CommandGroup className="">
                                        {prePopulatedValues.sort().map((populated) => (
                                            <CommandItem
                                                key={populated}
                                                value={populated}
                                                keywords={[populated]}
                                                onSelect={(currentValue) => {
                                                    handleSetValue(currentValue)
                                                }}
                                                className={cn(
                                                    'aria-selected:bg-accent aria-selected:text-accent-foreground flex items-center',
                                                    thisParam.value.includes(populated) &&
                                                        'bg-accent text-accent-foreground'
                                                )}
                                            >
                                                <Checkbox
                                                    checked={thisParam.value.includes(populated)}
                                                    className="w-4 h-4 mr-2 text-accent-foreground"
                                                />
                                                {populated}
                                            </CommandItem>
                                        ))}
                                    </CommandGroup>
                                </ScrollArea>
                            </CommandList>
                        </Command>
                    ) : (
                        <Command
                            filter={(_, search, keywords) => {
                                if (
                                    keywords?.join(' ').toLowerCase().includes(search.toLowerCase())
                                ) {
                                    return 1
                                }
                                return 0
                            }}
                        >
                            <CommandList>
                                <ScrollArea>
                                    <CommandEmpty>No filters found.</CommandEmpty>
                                    <CommandGroup className="">
                                        {prePopulatedValues
                                            .sort((a, b) =>
                                                column !== 'Date' ? a.localeCompare(b) : 0
                                            )
                                            .map((populated) => (
                                                <CommandItem
                                                    key={populated}
                                                    value={populated}
                                                    keywords={[populated]}
                                                    onSelect={(currentValue) => {
                                                        handleSetSingleValue(currentValue)
                                                    }}
                                                    className={cn(
                                                        'aria-selected:bg-accent aria-selected:text-accent-foreground flex items-center justify-between',
                                                        thisParam.value.includes(populated) &&
                                                            'bg-accent text-accent-foreground'
                                                    )}
                                                >
                                                    {populated}
                                                    {thisParam.value.includes(populated) && (
                                                        <Check className="w-4 h-4 text-accent-foreground" />
                                                    )}
                                                </CommandItem>
                                            ))}
                                    </CommandGroup>
                                </ScrollArea>
                            </CommandList>
                        </Command>
                    )}
                </PopoverContent>
            </Popover>
        </div>
    )
}

export default CompoundFilter
