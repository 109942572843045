import React, { useEffect, useState } from 'react'
import { ChevronDown, type LucideIcon } from 'lucide-react'
import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { DropdownMenuArrow } from '@radix-ui/react-dropdown-menu'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible'
import { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider } from '@/components/ui/tooltip'
import {
    DropdownMenu,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuTrigger,
    DropdownMenuContent,
    DropdownMenuSeparator,
} from '@/components/ui/dropdown-menu'
import { Link } from '@tanstack/react-router'
import { SideNavItem } from './SideNavItem'
import type { Submenu } from './MenuList'
import { useSideNavStore } from '@/stores/SideNavStore'
import type { CustomSubMenuFooterProps } from './CustomSubMenuFooter.tsx'
import { CustomSubMenuItemProps } from './CustomSubMenuItem.tsx'
import { CustomMenuActionProps } from './CustomMenuAction.tsx'

interface CollapseMenuButtonProps {
    icon: LucideIcon
    label: string
    active: boolean
    submenus: Submenu[]
    isOpen: boolean | undefined
    CustomMenuAction?: React.FC<CustomMenuActionProps>
    CustomSubMenuItem?: React.FC<CustomSubMenuItemProps>
    CustomSubMenuFooter?: React.FC<CustomSubMenuFooterProps>
    CustomDropdownMenuItem?: React.FC<CustomSubMenuItemProps>
    CustomDropdownMenuFooter?: React.FC<CustomSubMenuFooterProps>
}

export function CollapseMenuButton({
    icon: Icon,
    label,
    active,
    submenus,
    isOpen,
    CustomMenuAction,
    CustomSubMenuItem,
    CustomSubMenuFooter,
    CustomDropdownMenuItem,
    CustomDropdownMenuFooter,
}: CollapseMenuButtonProps) {
    const { isAccordionOpen, setIsAccordionOpen } = useSideNavStore()
    const [isTooltipVisible, setIsTooltipVisible] = useState(true)

    const updateAccordionState = (stateArray: string[]) => {
        if (stateArray.includes(label)) {
            setIsAccordionOpen(stateArray.filter((state) => state !== label))
        } else {
            setIsAccordionOpen([...stateArray, label])
        }
    }

    const checkAccordionState = (stateArray: string[]) => {
        return stateArray.includes(label)
    }

    const [isExpanded, setIsExpanded] = useState(false)

    useEffect(() => {
        setIsExpanded(checkAccordionState(isAccordionOpen))
    }, [isAccordionOpen])

    return isOpen ? (
        <Collapsible
            open={isExpanded}
            onOpenChange={() => updateAccordionState(isAccordionOpen)}
            className="w-full"
        >
            <CollapsibleTrigger
                className="[&[data-state=open]>div>div>svg]:rotate-180 mb-1"
                asChild
            >
                <Button
                    variant={'ghost'}
                    className={cn(
                        'w-full justify-start h-9 px-1 -ml-1 hover:bg-primary-100 hover:text-foreground',
                        active && !isExpanded && 'bg-primary-100 text-foreground'
                    )}
                >
                    <div className="w-full items-center flex justify-between">
                        <div className="w-full flex items-center justify-between mr-1">
                            <div className={'flex items-center'}>
                                <span className="mr-4">
                                    <Icon size={16} />
                                </span>
                                <p
                                    className={cn(
                                        'max-w-[150px] truncate',
                                        isOpen
                                            ? 'translate-x-0 opacity-100'
                                            : '-translate-x-96 opacity-0'
                                    )}
                                >
                                    {label}
                                </p>
                            </div>
                            {CustomMenuAction && <CustomMenuAction menuLabel={label} />}
                        </div>
                        <div
                            className={cn(
                                'whitespace-nowrap',
                                isOpen ? 'translate-x-0 opacity-100' : '-translate-x-96 opacity-0'
                            )}
                        >
                            <ChevronDown size={18} className="transition-transform duration-200" />
                        </div>
                    </div>
                </Button>
            </CollapsibleTrigger>
            <CollapsibleContent className="overflow-hidden data-[state=closed]:animate-collapsible-up data-[state=open]:animate-collapsible-down border-l-[1px] border-gray-250 ml-2 pl-3 px-1">
                {submenus.map(({ path, label: subLabel, params, id, disabled, custom }) => {
                    if (custom && CustomSubMenuItem) {
                        return (
                            //@ts-ignore
                            <CustomSubMenuItem
                                key={subLabel}
                                menuLabel={label}
                                subMenu={{ path, params, id, disabled, label: subLabel }}
                            />
                        )
                    } else {
                        return (
                            <SideNavItem
                                disabled={disabled}
                                path={path}
                                params={params}
                                key={subLabel}
                                title={subLabel}
                                className="ml-4"
                            />
                        )
                    }
                })}
                {CustomSubMenuFooter && <CustomSubMenuFooter menuLabel={label} />}
            </CollapsibleContent>
        </Collapsible>
    ) : (
        <DropdownMenu onOpenChange={() => setIsTooltipVisible(false)}>
            <TooltipProvider disableHoverableContent>
                <Tooltip delayDuration={100}>
                    <TooltipTrigger onMouseEnter={() => setIsTooltipVisible(true)} asChild>
                        <DropdownMenuTrigger asChild>
                            <Button
                                variant={'ghost'}
                                className={cn(
                                    'w-full justify-start h-9 p-0 hover:bg-primary-100 hover:text-foreground',
                                    active && !isOpen && 'bg-primary-100 text-foreground'
                                )}
                            >
                                {isOpen && (
                                    <div className="w-full items-center flex justify-between">
                                        <div className="flex items-center">
                                            <span className={cn(isOpen === false ? '' : 'mr-4')}>
                                                <Icon size={16} />
                                            </span>

                                            <p
                                                className={cn(
                                                    'max-w-[200px] truncate',
                                                    isOpen === false ? 'opacity-0' : 'opacity-100'
                                                )}
                                            >
                                                {label}
                                            </p>
                                        </div>
                                    </div>
                                )}
                                {!isOpen && <Icon className="m-auto" size={16} />}
                            </Button>
                        </DropdownMenuTrigger>
                    </TooltipTrigger>
                    {isTooltipVisible && (
                        <TooltipContent side="right" align="start" alignOffset={2}>
                            {label}
                        </TooltipContent>
                    )}
                </Tooltip>
            </TooltipProvider>
            <DropdownMenuContent side="right" sideOffset={25} align="start">
                <DropdownMenuLabel className="max-w-[190px] text-gray-550 truncate">
                    {label}
                </DropdownMenuLabel>
                <DropdownMenuSeparator />
                {submenus.map(({ path, label: subLabel, params, id, disabled, custom }) => {
                    if (custom && CustomDropdownMenuItem) {
                        return (
                            //@ts-ignore
                            <CustomDropdownMenuItem
                                key={subLabel}
                                menuLabel={label}
                                subMenu={{ path, params, id, disabled, label: subLabel }}
                            />
                        )
                    } else {
                        return (
                            <DropdownMenuItem
                                className="focus:bg-primary-100 focus:text-foreground"
                                disabled={disabled}
                                key={subLabel}
                                asChild
                            >
                                <Link
                                    className={
                                        'flex justify-between rounded-[8px] items-center text-sm hover:bg-primary-100 hover:text-foreground px-2 h-9 cursor-pointer'
                                    }
                                    activeProps={{
                                        className:
                                            'text-foreground bg-primary-100 hover:bg-primary-100 hover:text-foreground',
                                    }}
                                    activeOptions={{
                                        exact: false,
                                    }}
                                    to={path}
                                    params={params && params}
                                >
                                    <p className="max-w-[180px] truncate">{subLabel}</p>
                                </Link>
                            </DropdownMenuItem>
                        )
                    }
                })}
                {CustomDropdownMenuFooter && <CustomDropdownMenuFooter menuLabel={label} />}
                <DropdownMenuArrow className="fill-border" />
            </DropdownMenuContent>
        </DropdownMenu>
    )
}
