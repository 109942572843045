import { ModelLibraryView } from '@/components/modelLibrary/ModelLibrary'
import { paginationAndSortingParams } from '@/components/pagination/paginationHeader'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_mainLayout/library/models/')({
    component: Library,
})

function Library() {
    const search = Route.useSearch() as paginationAndSortingParams
    return <ModelLibraryView queryParams={search} />
}
