export enum QueryKeys {
    ALL_API_KEYS = 'all-api-keys',
    ALL_CREDENTIALS = 'all-credentials',
    ALL_DATASETS = 'all-datasets',
    ALL_DATASET_STATUSES = 'all-dataset-statuses',
    ALL_MEMORY = 'all-memory',
    ALL_MODELS = 'all-models',
    ALL_PIPELINES = 'all-pipelines',
    PIPELINES_BY_TOOLID = 'pipelines-by-toolid',
    ALL_PROMPTS = 'all-prompts',
    ALL_TRANSACTIONS = 'all-transactions',
    ALL_USERS = 'all-users',
    ALL_LIBRARY_MODELS = 'all-library-models',
    ALL_MODELS_INFINITE = 'all-models-infinite',
    ALL_DATASETS_INFINITE = 'all-datasets-infinite',
    ALL_PIPELINES_INFINITE = 'all-pipelines-infinite',
    ALL_MEMORY_INFINITE = 'all-memory-infinite',
    ALL_PROJECTS_INFINITE = 'all-projects-infinite',
    ALL_TOOLS = 'all-tools',
    ALL_OPERATIONS_EXECUTIONS = 'all-operations-executions',
    ALL_USER_PROMPTS = 'all-user-prompts',
    NEW_API_KEY = 'new-api-key',
    CURRENT_PROMPT = 'current-prompt',
    CURRENT_MODEL = 'current-model',
    CURRENT_USER = 'current-user',
    PROJECTS = 'projects',
    PROXY_QUERY = 'proxy-query',
    TENANT_PROXY_QUERY = 'tenant-proxy-query',
    SELECTED_PIPELINE = 'selected-pipeline',
    ACTIVE_TENANT = 'active-tenant',
    TELEMETRY = 'telemetry',
    TENANT = 'tenant',
    TENANT_DETAILS = 'tenant-details',
    TENANT_BALANCE = 'tenant-balance',
    ACTIVE_ORGANIZATION = 'active-organization',
    ORGANIZATIONS = 'organizations',
    FILE_LIST = 'file-list',
    MARKETPLACE_MODEL_DETAIL = 'marketplace-model-detail',
    HUGGING_FACE_MODEL_STATUS = 'hugging-face-model-status',
    MARKETPLACE_MODEL_TEXT = 'marketplace-models-text',
    MARKETPLACE_MODEL_IMAGE = 'marketplace-models-image',
    MARKETPLACE_MODELS = 'marketplace-models',
    GET_WS_URL = 'getWSUrl',
    USER_PROFILE = 'user-profile',
    PIPELINE_VERSION = 'pipeline-version',
    USERS = 'users',
    GROUPS = 'groups',
    USER_CONVERSATIONS = 'user-conversations',
    FIRST_CONVERSATION = 'first-conversation',
    LOGGED_IN_USER = 'logged-in-user',
    ALL_DATASOURCE_GROUPS = 'all-datasource-groups',
    DATA_SOURCE_FORMS = 'data-source-forms',
    DATASOURCE_STATUS = 'datasource-status',
    AI_GATEWAY_URL = 'ai-gateway-url',
    IDENTITY_PROVIDER = 'identity-provider',
    TOOLS = 'tools',
    HELP_CENTER_CONFIG = 'help-center-config',
    HELP_CENTER_QUESTIONS = 'help-center-questions',
    ALL_DEPLOYMENTS = 'all-deployments',
    EXECUTION_FEED_ALL = 'executions-feed-all-query',
    GATEWAY_FEED_ALL = 'gateway-feed-all-query',
    INGESTION_FEED_ALL = 'ingestion-feed-all',
    FEED_EXECUTION_DETAILS = 'feed-execution-details',
    FEED_DETAILS = 'feed-details',
    CREDENTIALS_BY_PROJECT = 'credentials-by-project',
    VECTOR_SEARCH_FEED = 'vector-search-feed',
    PAGINATED_LIBRARY_MODELS = 'paginated-library-models',
}
