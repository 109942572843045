import SelectProjectForm from '@/components/modelLibrary/selectProjectForm'
import { ToolLibraryCard } from '@/components/tools/ToolLibraryCard'
import { Dialog, DialogContent } from '@/components/ui/dialog'
import { QueryKeys } from '@/constants/QueryKeys'
import { useQuery } from '@tanstack/react-query'
import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { LayoutGrid, List } from 'lucide-react'
import { useEffect, useState } from 'react'
import { getAllLibraryTools, LibaryTool } from '@/api/library.ts'
import MegaSearch from '@/components/megaSearch/MegaSearch.tsx'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs.tsx'
import { ColumnDef } from '@tanstack/react-table'
import { DefaultDataTable } from '@/components/DefaultDataTable.tsx'
import { Badge } from '@/components/ui/badge.tsx'

export const Route = createFileRoute('/_mainLayout/integrations/tools/')({
    component: () => <ToolLibrary />,
})

function ToolLibrary() {
    const [search, setSearch] = useState('')
    const [filteredTools, setFilteredTools] = useState<any[]>([])
    const [addToProjectOpen, setAddToProjectOpen] = useState(false)
    const navigate = useNavigate()
    const [selectedToolId, setSelectedToolId] = useState('')

    const toolsQuery = useQuery({
        queryKey: [QueryKeys.ALL_TOOLS],
        queryFn: () => getAllLibraryTools(),
    })

    useEffect(() => {
        if (search !== '' && toolsQuery.data) {
            setFilteredTools(
                toolsQuery.data?.filter((tool) =>
                    tool.name.toLowerCase().includes(search.toLowerCase())
                ) ?? []
            )
        } else {
            setFilteredTools(toolsQuery.data ?? [])
        }
    }, [search, toolsQuery.data])

    const onItemClick = (toolId: string) => {
        setSelectedToolId(toolId)
        setAddToProjectOpen(true)
    }

    const onAddToProject = (projectId: string) => {
        navigate({
            to: '/$projectId/tools/new',
            params: { projectId },
            search: { libraryToolId: selectedToolId },
        })
    }

    const columns: ColumnDef<LibaryTool>[] = [
        {
            accessorKey: 'name',
            header: 'Name',
            cell: ({ row }) => (
                <div className="flex flex-col gap-1 ">
                    <p className="font-medium text-sm text-gray-550">{row.original.name}</p>
                    <p className="text-ellipsis line-clamp-1 text-sm text-gray-450">
                        {row.original.description}
                    </p>
                </div>
            ),
        },
        {
            accessorKey: 'provider',
            header: 'Provider',
        },
        {
            accessorKey: 'authRequired',
            header: 'Authorization',
            cell: ({ row }) => <span>{row.original.authRequired ? 'Required' : 'None'}</span>,
        },
        {
            id: 'tags',
            header: 'Tags',
            cell: ({ row }) => {
                return (
                    <div className="flex flex-row content-center items-center gap-2 flex-wrap min-w-56">
                        {row.original.tags.slice(0, 2).map(
                            (tag) =>
                                tag !== '' && (
                                    <Badge
                                        key={tag}
                                        title={tag}
                                        className="rounded-lg bg-gray-250 text-gray-450 text-xs hover:text-gray-450 cursor-default line-clamp-1"
                                    >
                                        {tag}
                                    </Badge>
                                )
                        )}
                        {row.original.tags.length > 2 && (
                            <Badge
                                title={row.original.tags.slice(2).join(', ')}
                                className="rounded-lg bg-gray-250 text-gray-450 text-xs hover:text-gray-450 cursor-default"
                            >
                                {`+${row.original.tags.length - 2}`}
                            </Badge>
                        )}
                    </div>
                )
            },
        },
    ]

    return (
        <div>
            <div className={'px-6 h-full'}>
                <Tabs className={'flex flex-col gap-5'} defaultValue={'list'}>
                    <div className={'my-6 px-[15%]'}>
                        <MegaSearch onSearch={setSearch} placeholder={'Search by Tool Name'} />
                    </div>
                    <div className={'flex justify-end'}>
                        <div>
                            <TabsList>
                                <TabsTrigger value={'list'}>
                                    <List size={18} />
                                </TabsTrigger>
                                <TabsTrigger value="cards">
                                    <LayoutGrid size={18} />
                                </TabsTrigger>
                            </TabsList>
                        </div>
                    </div>
                    <TabsContent value={'cards'}>
                        <div className={'grid gap-4 grid-cols-3 xl:grid-cols-4'}>
                            {filteredTools.map((tool) => (
                                <ToolLibraryCard
                                    onCardClick={onItemClick}
                                    key={tool.id}
                                    tool={tool}
                                />
                            ))}
                        </div>
                    </TabsContent>
                    <TabsContent value={'list'}>
                        <DefaultDataTable
                            columns={columns}
                            data={filteredTools}
                            onRowClick={(row) => onItemClick(row.original.id)}
                        />
                    </TabsContent>
                </Tabs>
            </div>
            <Dialog open={addToProjectOpen} onOpenChange={setAddToProjectOpen}>
                <DialogContent>
                    <SelectProjectForm onSelectProjectSubmit={onAddToProject} />
                </DialogContent>
            </Dialog>
        </div>
    )
}
